import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import PackageSpecialistAppointment from "./PackageSpecialistAppointments";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Packages: React.FC = () => {
  const [packageList, setPackageList] = useState<any>([]);
  const [activeTitle, setActiveTitle] = useState<any>("");
  const [packageUuid, setPackageUuid] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [categoryName2, setCategoryName] = useState<string | null>(null);
  const [categoryId2, setCategoryId] = useState<string | null>(null);
  const [urlParams, setUrlParams] = useState<any>("");
  const [packagePuckData, setPackagePuckData] = useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const newUrlParams = new URLSearchParams(location.search);
    const newCategoryName = newUrlParams.get("categoryname");
    const newCategoryId = newUrlParams.get("categoryid");
    setCategoryName(newCategoryName);
    setCategoryId(newCategoryId);
    setUrlParams(newUrlParams);
  }, [location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPackageData();
  }, []);

  useEffect(() => {
    const userRole = localStorage.getItem("userRole");
    const token = localStorage.getItem("token");
    setRole(userRole);

    if (userRole !== '"user"' && !token) {
      Swal.fire({
        title: "Please login before adding the product to your cart.",
        showCancelButton: true,
        cancelButtonText: "OK",
        confirmButtonText: "LOGIN",
        showClass: {
          popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `,
        },
        hideClass: {
          popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `,
        },
        customClass: {
          container: "login__pop--packages",
          confirmButton: "custom-confirm-button",
          cancelButton: "custom-cancel-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = `/app/login?callback2=/packages`;
          return;
        }
      });
    }
  }, [role !== '"user"']);

  const getPackageList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 5000,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "packageList",
            appName: "selfMaximized",
            folder: "packages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.code === 1) {
          setPackageList(result?.data?.data);
        } else {
          return toast.info(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const packageListCB = useCallback(getPackageList, []);

  useEffect(() => {
    packageListCB();
  }, [packageListCB]);

  const handleActiveTitle = (title: any, packageUuid: any) => {
    if (title) {
      setActiveTitle(title);
      setPackageUuid(packageUuid);
      getPackageList();
      if (urlParams) {
        urlParams.delete("categoryname");
        urlParams.delete("categoryid");
        const newUrl = window.location.pathname + urlParams.toString();
        window.history.replaceState(null, "", newUrl);
        setCategoryName(null);
        setCategoryId(null);
      }
    }
  };

  // useEffect(() => {
  //     setCategoryName(null);
  //     setCategoryId(null);
  // }, [activeTitle]);

  // useEffect(()=> {
  //     setActiveTitle('')
  // }, [categoryName2 !== null ? categoryName2 : undefined, categoryName2])

  useEffect(() => {
    if (packageList.length !== 0 && packageList[0] !== null) {
      setActiveTitle(
        packageList.length !== 0 && packageList[0] !== null
          ? packageList[0].title
          : null
      );
      setPackageUuid(
        packageList.length !== 0 && packageList[0] !== null
          ? packageList[0].uuid
          : null
      );
    }
  }, [packageList.length !== 0 && packageList[0] !== null]);

  const getPackageData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "PACKAGES",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setPackagePuckData(data);
        } else {
          setPackagePuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const pkgPuckData: any = packagePuckData?.content?.find(
    (elem: any) => elem.type === "Package"
  );

  return (
    <>
      <section
        className="custom-pt-1 custom-pb-2 parallaxie position-relative journal-detail-page-wrapper h-100"
        data-overlay="6"
      >
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner">
                <h1 className="mb-0 banner-heading">
                  {pkgPuckData?.props?.title
                    ? pkgPuckData?.props?.title
                    : "PACKAGES"}
                </h1>
                <p className="mb-0 banner-description text-uppercase">
                  {pkgPuckData?.props?.description
                    ? pkgPuckData?.props?.description
                    : `Medcity has been present in Europe since 1990, offering
                  innovative solutions, specializing in medical services for
                  treatment of medical infrastructure.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-content">
        {/* <section className="search-bar-with-dropdown py-0 mb-3">
                    <div className="contaier-fluid left-right-space">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="header__search w-100">
                                        <form className="form-search">
                                            <div className="form-search__container">
                                                <span className="form-search__icon-left">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                                <input className="form-search__input" type="text" placeholder="FILTER" />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="filter_dropdown_package ps-1">
                                        <button type="button" className="images-drop-down_package">
                                            <i className="fas fa-filter"></i>
                                        </button>
                                        <div
                                            className="images_packages animated-package fadeIn_package mt-2 container position-absolute">
                                            <div className="row text-start">
                                                <div className="col-lg-12 px-0">
                                                    <p className="mb-0 sort-by-text py-2">SINGULARA APPOINTMENT OPTIONS</p>
                                                    <p className="mb-0 sort-by-text pb-2">SINGULARA APPOINTMENT OPTIONS</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="py-0 packages__wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="blog_page_section mb-140 p-0">
                <div className="blog_messonry_button d-flex justify-content-start justify-content-md-between">
                  {packageList.length !== 0 && packageList !== null ? (
                    packageList.map((elem: any) => (
                      <button
                        className={`${
                          categoryName2 !== null && categoryName2 === elem.title
                            ? "active"
                            : categoryName2 === null
                            ? activeTitle === elem.title && "active"
                            : null
                        }`}
                        onClick={() => handleActiveTitle(elem.title, elem.uuid)}
                        key={elem.id}
                      >
                        {elem.title}
                      </button>
                    ))
                  ) : (
                    <button
                      className="text-uppercase"
                      data-filter=".home-balancing-package"
                    >
                      No package available
                    </button>
                  )}
                </div>
                {packageUuid ? (
                  <PackageSpecialistAppointment
                    packageUuid={categoryId2 ? categoryId2 : packageUuid}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Packages;
