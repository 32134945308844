import React from "react";
// import cardImage from "../../../web/src/images/dummy/card.png";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { format, addDays, startOfWeek, endOfWeek, isSameWeek } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { DateRangePicker } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface Slot {
  uuid: string;
  calendarDay: string;
  isBooked: number;
  // Add other properties as needed
}

interface GroupedSlots {
  [day: string]: Slot[];
}
const BuyProduct: React.FC = () => {
  const [specialistProducts, setSpecialistProducts] = useState<any>([]);
  const [ipAddress, setIpAddress] = useState<any>("");
  const [productPrice, setProductPrice] = useState<any>("");
  const [cartId, setCartId] = useState<any>("");
  const [productUuid, setProductUuid] = useState<any>("");
  const [productId, setProductId] = useState<any>("");
  const [slots, setSlots] = useState<any>([]);
  const [orgID, setOrgId] = useState<any>("");
  const [specialistImage, setSpecialistImage] = useState<any>("");
  const [specialistName, setSpecialistName] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const [dayName, setDayName] = useState<any>("");
  const [productDuration, setProductDuration] = useState<any>("");
  const [buyNowCartId, setBuyNowCartId] = useState<any>("");
  const [orderId, setOrderId] = useState<any>("");
  const today = new Date();
  const [startDate, setStartDate] = useState<any>(startOfWeek(today));
  const [endDate, setEndDate] = useState<any>(endOfWeek(today));
  const [showPicker, setShowPicker] = useState(false);
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState<any>(
    startOfWeek(today)
  );

  // useEffect(() => {
  //   const thisWeekStart = startOfWeek(today);
  //   if (!isSameWeek(startDate, thisWeekStart)) {
  //     setCurrentWeekStartDate(thisWeekStart);
  //   }
  // }, [startDate, today, currentWeekStartDate]);

  const handleNextWeek = async () => {
    const newStartDate = addDays(startDate, 7);
    const newEndDate = addDays(endDate, 7);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setShowPicker(false); // Hide the calendar part
    await getSpecialistBookingSlots(newStartDate, newEndDate);
  };

  const handlePreviousWeek = async () => {
    if (!isSameWeek(startDate, currentWeekStartDate)) {
      const newStartDate = addDays(startDate, -7);
      const newEndDate = addDays(endDate, -7);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setShowPicker(false); // Hide the calendar part
      await getSpecialistBookingSlots(newStartDate, newEndDate);
    }
  };

  const handleDateRangeClick = () => {
    setShowPicker(!showPicker);
  };

  const handleRangeSelection = (ranges: any) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const handleOkClick = async () => {
    setShowPicker(false); // Hide the calendar part
    await getSpecialistBookingSlots("", "");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let organizationId = localStorage.getItem("organization");

  const { id, categoryid } = useParams();
  const userUUID = id;

  useEffect(() => {
    setOrgId(organizationId);
  }, [organizationId, cartId]);

  const handleDateChange = () => {
    getSpecialistBookingSlots("", "");
  };

  const navigate = useNavigate();

  const getSpecialistBookingSlots = async (
    newStartDate: any,
    newEndDate: any
  ) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "calendarDate",
                  operator: "between",
                  table: "BookingCalendar",
                  value: moment(newStartDate ? newStartDate : startDate).format(
                    "YYYY-MM-DD"
                  ),
                  opr: "AND",
                  value2: moment(newEndDate ? newEndDate : endDate).format(
                    "YYYY-MM-DD"
                  ),
                },
                {
                  field: "doctorId",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: specialistProducts[0].doctorid,
                },
                {
                  field: "Duration",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: productDuration,
                },
              ],
              sort: [
                {
                  field: "calendarDate",
                  table: "BookingCalendar",
                  dir: "asc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "specialistCalendarBooking",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          const responseData: Slot[] = result?.data?.data?.response || [];

          const groupedSlots: GroupedSlots = responseData.reduce(
            (acc: any, slot: any) => {
              acc[slot.calendarDay] = [...(acc[slot.calendarDay] || []), slot];
              return acc;
            },
            {}
          );

          const slotsArray: any = Object.entries(groupedSlots)
            .filter(([day, slots]) =>
              slots.some((slot: Slot) => slot.isBooked === 0)
            )
            .map(([day, slots]) => ({
              dayOfWeek: day,
              slots: slots.filter((slot: Slot) => slot.isBooked === 0),
            }));
          setSlots(slotsArray);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const specialistProductList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 50,
              filters: [
                {
                  field: "uuid",
                  operator: "eq",
                  table: "Users",
                  value: `${userUUID}`,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
          action: "command",
          command: [
            {
              agent: "productList",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        setSpecialistProducts(result?.data?.data);
        setSpecialistImage(result?.data?.data[0].profile);
        setSpecialistName(result?.data?.data[0].name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let data = localStorage.getItem("userInfo");
  const userInfo = typeof data === "string" ? JSON.parse(data) : data;
  let lastActiveCartId = userInfo.lastActiveCart;
  let cartIdlocalStorage = localStorage.getItem("cartId");
  const userRole = localStorage.getItem("userRole");
  const token = localStorage.getItem("token");

  let decryptedRole: any;

  if (userRole && token) {
    const decryptUserRole = (encryptedRole: any, key: any) => {
      const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
      return bytes.toString(CryptoJS.enc.Utf8);
    };

    decryptedRole = decryptUserRole(userRole, "98760");
  }

  const goToAddToCartPage = async () => {
    if (!userRole && !token) {
      window.location.href = `/app/login?callback=/buyproduct/${userUUID}`;
      return;
    }

    if (!productUuid) {
      return toast.warning("Select product first");
    }

    if (!selectedSlot) {
      return toast.warning("Select slot first");
    }

    if (!cartIdlocalStorage && !lastActiveCartId) {
      await axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            ip: ipAddress,
            action: "command",
            command: [
              {
                agent: "createCart",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          },
          {
            headers: { "x-access-token": token },
          }
        )
        .then(async (result) => {
          if (result?.data?.code === 1) {
            setCartId(result?.data?.data?.cartId);
            localStorage.setItem("cartId", result?.data?.data?.cartId);
            if (decryptedRole === '"user"') {
              await addProductToCart();
              navigate("/buyproduct", { state: userUUID });
            }
          } else {
            toast.info("Register as an user first");
            return;
          }
        });
    } else if (
      (decryptedRole === '"user"' && cartIdlocalStorage) ||
      lastActiveCartId
    ) {
      await addProductToCart();
      navigate("/cart", { state: userUUID });
    } else {
      navigate(`/app/login?callback=/buyproduct/${userUUID}`, {
        state: "user",
      });
      window.location.reload();
      return;
    }
  };

  const handleProducts = async (product: any) => {
    setProductId(product.id);
    setProductPrice(product.price);
    setProductUuid(product.uuid);
    setSlots([]);
    setSelectedDate(null);
    setProductDuration(product.duration);
  };

  const passSlots = (selectedSlot: any) => {
    setSelectedSlot(selectedSlot);
  };
  const addProductToCart = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          cartId: lastActiveCartId
            ? lastActiveCartId
            : cartId || cartIdlocalStorage,
          productId: productUuid,
          ip: ipAddress,
          organizationId: 1,
          metadata: [
            {
              productId: productId,
              doctorId: specialistProducts[0].doctorid,
              StartTime: selectedSlot.StartTime,
              EndTime: selectedSlot.EndTime,
              Duration: selectedSlot.Duration,
              Day: selectedSlot.calendarDay,
              Date: selectedSlot.calendarDate,
              categoryid: categoryid,
            },
          ],
          qty: 1,
          action: "command",
          command: [
            {
              agent: "addToCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then(async (result) => {
        if (result?.data?.data.length === 0) {
          if (
            userInfo.lastActiveCart &&
            result.data.message ===
              "Selected cart is not acitve, please create new cart" &&
            !localStorage.getItem("cartId")
          ) {
            await axios
              .post(
                `${process.env.REACT_APP_API}/pipeline`,
                {
                  ip: ipAddress,
                  action: "command",
                  command: [
                    {
                      agent: "createCart",
                      appName: "selfMaximized",
                      folder: "order",
                    },
                  ],
                },
                {
                  headers: { "x-access-token": token },
                }
              )
              .then(async (result) => {
                localStorage.setItem("cartId", result?.data?.data?.cartId);
                if (result?.data?.statusCode == 200) {
                  await axios
                    .post(
                      `${process.env.REACT_APP_API}/pipeline`,
                      {
                        cartId: result?.data?.data?.cartId,
                        productId: productUuid,
                        ip: ipAddress,
                        organizationId: 1,
                        metadata: [
                          {
                            productId: productId,
                            doctorId: specialistProducts[0].doctorid,
                            StartTime: selectedSlot.StartTime,
                            EndTime: selectedSlot.EndTime,
                            Duration: selectedSlot.Duration,
                            Day: selectedSlot.calendarDay,
                            Date: selectedSlot.calendarDate,
                            categoryid: categoryid,
                          },
                        ],
                        qty: 1,
                        action: "command",
                        command: [
                          {
                            agent: "addToCart",
                            appName: "selfMaximized",
                            folder: "order",
                          },
                        ],
                      },
                      {
                        headers: { "x-access-token": token },
                      }
                    )
                    .then((result) => {
                      if (result?.data?.code === 200) {
                        return toast.success(result?.data?.message);
                      } else {
                        return toast.error(result?.data?.message);
                      }
                    })
                    .catch((err) => {
                      return toast.error("Something went wrong", err);
                    });
                }
              })
              .catch((err) => {
                return toast.error("Something went wrong", err);
              });
          }
          // if (
          //   !userInfo.lastActiveCart &&
          //   result.data.message !==
          //     "Selected cart is not acitve, please create new cart"
          // ) {
          //   return toast.info(result.data.message);
          // }
          else if (localStorage.getItem("cartId")) {
            await axios
              .post(
                `${process.env.REACT_APP_API}/pipeline`,
                {
                  cartId: localStorage.getItem("cartId"),
                  productId: productUuid,
                  ip: ipAddress,
                  organizationId: 1,
                  metadata: [
                    {
                      productId: productId,
                      doctorId: specialistProducts[0].doctorid,
                      StartTime: selectedSlot.StartTime,
                      EndTime: selectedSlot.EndTime,
                      Duration: selectedSlot.Duration,
                      Day: selectedSlot.calendarDay,
                      Date: selectedSlot.calendarDate,
                      categoryid: categoryid,
                    },
                  ],
                  qty: 1,
                  action: "command",
                  command: [
                    {
                      agent: "addToCart",
                      appName: "selfMaximized",
                      folder: "order",
                    },
                  ],
                },
                {
                  headers: { "x-access-token": token },
                }
              )
              .then((result) => {
                if (result?.data?.statusCode === 200) {
                  toast.success("Item added to cart");
                }
              });
          }
        }
      })
      .catch((err) => {
        return toast.error(err);
      });
  };

  const specialistProductListCB = useCallback(specialistProductList, [
    userUUID,
  ]);

  useEffect(() => {
    specialistProductListCB();
  }, []);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIpAddress(data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const redirectFromLoginPage = async (role: any, token: any) => {
    if (role === '"user"') {
      await axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            ip: ipAddress,
            action: "command",
            command: [
              {
                agent: "createCart",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          },
          {
            headers: { "x-access-token": token },
          }
        )
        .then(async (result) => {
          if (result?.data?.code === 1) {
            setCartId(result?.data?.data?.cartId);
            localStorage.setItem("cartId", result?.data?.data?.cartId);
          } else {
            toast.info("Register as an user first");
            return;
          }
        });
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("userInfo");
    const userInfo = typeof data === "string" ? JSON.parse(data) : data;
    let cartIdlocalStorage = userInfo.lastActiveCart;
    const userRole = localStorage.getItem("userRole");
    const token = localStorage.getItem("token");
    const cartId = localStorage.getItem("cartId");

    let decryptedRole;
    if (userRole && token) {
      const decryptUserRole = (encryptedRole: any, key: any) => {
        const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
        return bytes.toString(CryptoJS.enc.Utf8);
      };

      decryptedRole = decryptUserRole(userRole, "98760");
    }

    if (!cartId && !cartIdlocalStorage && userRole && token) {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          setIpAddress(data.ip);
        })
        .catch((error) => {
          console.error("Error fetching IP address:", error);
        });

      redirectFromLoginPage(decryptedRole, token);
    }
  }, []);

  const buyNowProduct = async () => {
    if (!productUuid) {
      return toast.warning("Select product first");
    }

    if (!selectedSlot) {
      return toast.warning("Select slot first");
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          ip: ipAddress,
          action: "command",
          command: [
            {
              agent: "createCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then(async (result) => {
        if (result?.data?.code === 1) {
          setBuyNowCartId(result?.data?.data?.cartId);
          await addBuyNowProductToCart(result?.data?.data?.cartId);
        } else {
          toast.info("Register as an user first");
          return;
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const addBuyNowProductToCart = async (buyNowCartId: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          cartId: buyNowCartId,
          productId: productUuid,
          ip: ipAddress,
          organizationId: 1,
          metadata: [
            {
              productId: productId,
              doctorId: specialistProducts[0].doctorid,
              StartTime: selectedSlot.StartTime,
              EndTime: selectedSlot.EndTime,
              Duration: selectedSlot.Duration,
              Day: selectedSlot.calendarDay,
              Date: selectedDate,
              categoryid: categoryid,
            },
          ],
          qty: 1,
          action: "command",
          command: [
            {
              agent: "addToCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then(async (result) => {
        if (result?.data?.data.length === 0) {
          return toast.info(result.data.message);
        } else {
          await createOrderId(buyNowCartId);
        }
      })
      .catch((err) => {
        return toast.error(err);
      });
  };

  const createOrderId = async (cartId: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          successurl: "http://localhost:3000/success",
          cancelurl: "http://localhost:3000/cancel",
          cartId: cartId,
          organizationId: 1,
          action: "command",
          command: [
            {
              agent: "createOrder",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setOrderId(result?.data?.data?.orderID);
          navigate(`/checkout/${result?.data?.data?.orderID}`, {
            state: cartId,
          });
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className="custom-pt-1 custom-pb-2 parallaxie position-relative journal-detail-page-wrapper h-100">
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner">
                <h1 className="mb-0 banner-heading">PRODUCT</h1>
                <p className="mb-0 banner-description">
                  "Revolutionizing Healthcare: Product streamlines medical
                  workflows for efficient patient care."
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-content">
        <section className="cart__page__wrapper py-0 cart__product__quantity__total position-relative">
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12 pb-4 pb-lg-0">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-4 pb-4 pb-lg-0">
                    <div className="buy__now__product__image">
                      <img
                        src={
                          specialistImage
                            ? specialistImage
                            : "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                        }
                        alt=""
                        className="img-fluid w-100 buy__now__product__image--image"
                        style={{ width: "auto", height: "auto" }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-8">
                    <div className="buy__product__right__card p-3">
                      <div className="cart__title__buy__now__add__to__cart">
                        <h3 className="mb-0 buy__product__title pb-2 text-white">
                          DR.{specialistName.toUpperCase()}
                        </h3>
                        <div className="col-lg-12">
                          {specialistProducts.length !== 0 &&
                          specialistProducts !== null ? (
                            specialistProducts.map((elem: any) => (
                              <div
                                className={`d-flex justify-content-between align-items-center appoinment_status py-2 px-3 mb-4 ${
                                  elem.uuid === productUuid
                                    ? "package__active"
                                    : null
                                } cursor_pointer`}
                                key={elem.id}
                                onClick={() => handleProducts(elem)}
                              >
                                <div>
                                  <p className="mb-0 package__title__description">
                                    <span className="pe-2">
                                      {elem.shorttitle}
                                    </span>
                                    {elem.duration} HR VIRTUAL APPOINTMENT
                                  </p>
                                </div>
                                <div className="">
                                  <div className="package-amount-in-dollar px-2 py-1">
                                    <div className="d-flex align-items-center">
                                      <span className="pe-1">
                                        <i className="fa-solid fa-dollar-sign"></i>
                                      </span>
                                      <span className="amount-dollar_package ps-1">
                                        {elem.price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="pb-3">
                              <span className="no__product__available">
                                No Products Available
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-block d-md-flex align-items-center">
                        {productUuid ? (
                          <div className="increament__decreament">
                            <button
                              className="incrdecreament letter_spacing selects--slots"
                              id="openModal2"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={handleDateChange}
                            >
                              SELECT SLOTS
                            </button>
                          </div>
                        ) : null}

                        {selectedSlot && (
                          <p className="time__selected mb-0 ps-0 ps-md-3 pt-3 pt-lg-0">
                            {selectedSlot.StartTime} - {selectedSlot.EndTime}{" "}
                            slot selected for {selectedSlot.calendarDay}
                          </p>
                        )}
                      </div>
                      <div className="d-flex py-3 align-items-center">
                        <div className="total__card">
                          {productPrice && (
                            <p className="mb-0">
                              <span className="buy__product__dollar buy__product--dollar-symbol">
                                $
                              </span>
                              <span className="amt buy__product__amount ps-2">
                                {productPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Desktop */}
                      <div className="d-none d-lg-block">
                        <button
                          className="buy__now  buy__btn but_now-btn"
                          onClick={buyNowProduct}
                        >
                          BUY NOW
                        </button>
                        <button
                          className="procced__to__buy ms-2 buy__btn add_to-cart"
                          onClick={goToAddToCartPage}
                        >
                          ADD TO CART
                        </button>
                      </div>
                      {/* Mobile */}
                      <div className="d-block d-lg-none d-grid gap-2">
                        <button
                          className="buy__now  buy__btn d-block mb-3 but_now-btn"
                          onClick={buyNowProduct}
                        >
                          BUY NOW
                        </button>
                        <button
                          className="procced__to__buy buy__btn add_to-cart"
                          onClick={goToAddToCartPage}
                        >
                          ADD TO CART
                        </button>
                      </div>
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        style={{ zIndex: "99999999999999" }}
                      >
                        <div className="modal-dialog modal-dialog-centered user__calander__booking--list modal-xl">
                          <div className="modal-content buy__product__modal">
                            <div className="modal-header py-2">
                              <p className="available-slots-doctor mb-0">
                                Available Slots: DR.
                                {specialistName.toUpperCase()}
                              </p>
                              <div className="available__slots__days__week d-flex align-items-center">
                                <span className="text-white days__in__number">
                                  07
                                </span>{" "}
                                <span className="week--text ps-2">
                                  days a week
                                </span>
                              </div>
                            </div>
                            <div className="modal-body pt-0">
                              <p className="mb-0 please__select__time__slot">
                                Please select your time slot before we process
                              </p>
                              <div>
                                <div className="mt-2 d-flex">
                                  {/* <button onClick={handlePreviousWeek} disabled={isSameWeek(startDate, currentWeekStartDate)}>
                                                                        Previous Week
                                                                    </button> */}
                                  <span
                                    className={"text-white ps-2 fs-4"}
                                    onClick={handlePreviousWeek}
                                    style={{
                                      cursor: "pointer",
                                      opacity: isSameWeek(
                                        startDate,
                                        currentWeekStartDate
                                      )
                                        ? 0.5
                                        : 1,
                                    }}
                                  >
                                    &laquo;
                                  </span>
                                  <span
                                    onClick={handleDateRangeClick}
                                    className="text-white ps-2 mb-5 fs-4 cursor_pointer"
                                  >
                                    {moment(startDate).format("YYYY-MM-DD")} -{" "}
                                    {moment(endDate).format("YYYY-MM-DD")}
                                  </span>
                                  <span
                                    className={
                                      "text-white ps-2 cursor_pointer fs-4"
                                    }
                                    onClick={handleNextWeek}
                                  >
                                    &raquo;
                                  </span>
                                </div>
                                {showPicker && (
                                  <div className="datepicker-modal">
                                    <DateRangePicker
                                      minDate={today}
                                      onChange={handleRangeSelection}
                                      moveRangeOnFirstSelection={false}
                                      months={1}
                                      ranges={[
                                        {
                                          startDate,
                                          endDate,
                                          key: "selection",
                                        },
                                      ]}
                                    />
                                    <button
                                      onClick={handleOkClick}
                                      className="btn btn-dark btn-sm"
                                    >
                                      OK
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div
                                className="row pt-3 scrollBarColor"
                                style={{
                                  overflowY: "auto",
                                  maxHeight: "380px",
                                }}
                              >
                                {slots.map((slot: any, index: any) => (
                                  <>
                                    <div className="row" key={index}>
                                      <h3 className="text-white ps-4 letter_spacing">
                                        {slot.dayOfWeek}
                                      </h3>
                                      {slot.slots.map((elem: any) => (
                                        <div
                                          className={`pb-3 ${
                                            elem.isBooked === 0
                                              ? "availableBgColor"
                                              : null
                                          } row col-lg-3 pe-5 ps-5`}
                                          key={elem.uuid}
                                          onClick={() => {
                                            passSlots(elem);
                                          }}
                                        >
                                          <div
                                            className={`p-2 ps-3 ${
                                              elem.isBooked === 0
                                                ? "available__time__duration"
                                                : null
                                            } ${
                                              elem.isBooked === 0
                                                ? "availableBgColor"
                                                : "bookedBgColor"
                                            } ${
                                              selectedSlot === elem
                                                ? "white_color"
                                                : "text-dark"
                                            }`}
                                          >
                                            <span
                                              className={`available__time--duration d-block ${
                                                selectedSlot === elem
                                                  ? "textdark"
                                                  : ""
                                              }`}
                                            >
                                              {elem.isBooked === 0
                                                ? "Availability"
                                                : "Booked"}
                                            </span>
                                            <span
                                              className={`d-block available__time ${
                                                selectedSlot === elem
                                                  ? "textdark"
                                                  : "text-white"
                                              }`}
                                            >
                                              {elem.StartTime} - {elem.EndTime}
                                            </span>
                                            <span
                                              className={`duration  ${
                                                selectedSlot === elem
                                                  ? "textdark"
                                                  : "text-white"
                                              }`}
                                            >
                                              {elem.Duration} HR
                                            </span>
                                            <span
                                              className={`available__time--duration d-block ${
                                                selectedSlot === elem
                                                  ? "textdark"
                                                  : ""
                                              }`}
                                            >
                                              {elem.calendarDate} |{" "}
                                              {elem.calendarDay}
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <hr className="text-white" />
                                  </>
                                ))}
                              </div>
                              <div className="row">
                                <div className="col-lg-12 text-end">
                                  <button
                                    className="procced__to__buy ms-2 buy__btn"
                                    data-bs-dismiss="modal"
                                  >
                                    BOOK
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default BuyProduct;
