import React, { useEffect } from "react";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="pb-0 user-registration-flow-wrapper contact-us_wrapper_title">
        <div className="container-fluid">
          <div className="row py-4">
            <div className="col-lg-12">
              <h1 className="user-registration-title mb-0 text-center">
                PRIVACY POLICY
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0 user-registration-flow-wrapper py-0">
        <div className="container-fluid left-right-space">
          <div className="row">
            <div className="col-lg-12 pb-5">
              <h2 className="title-heading mb-0">LOREM IPSUM</h2>
              <p className="mb-0 banner-description text-uppercase">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
                corrupti eum doloribus necessitatibus incidunt temporibus numquam
                fugit rem veniam, officiis obcaecati, maiores doloremque ut. Autem
                error a harum necessitatibus eius.
              </p>
            </div>
            <div className="col-lg-12 pb-5">
              <h2 className="title-heading mb-0">LOREM IPSUM</h2>
              <p className="mb-0 banner-description text-uppercase">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
                corrupti eum doloribus necessitatibus incidunt temporibus numquam
                fugit rem veniam, officiis obcaecati, maiores doloremque ut. Autem
                error a harum necessitatibus eius.
              </p>
            </div>
            <div className="col-lg-12 pb-5">
              <h2 className="title-heading mb-0">LOREM IPSUM</h2>
              <p className="mb-0 banner-description text-uppercase">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
                corrupti eum doloribus necessitatibus incidunt temporibus numquam
                fugit rem veniam, officiis obcaecati, maiores doloremque ut. Autem
                error a harum necessitatibus eius.
              </p>
            </div>
            <div className="col-lg-12 pb-5">
              <h2 className="title-heading mb-0">LOREM IPSUM</h2>
              <p className="mb-0 banner-description text-uppercase">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
                corrupti eum doloribus necessitatibus incidunt temporibus numquam
                fugit rem veniam, officiis obcaecati, maiores doloremque ut. Autem
                error a harum necessitatibus eius.
              </p>
            </div>
            <div className="col-lg-12 pb-5">
              <h2 className="title-heading mb-0">LOREM IPSUM</h2>
              <p className="mb-0 banner-description text-uppercase">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
                corrupti eum doloribus necessitatibus incidunt temporibus numquam
                fugit rem veniam, officiis obcaecati, maiores doloremque ut. Autem
                error a harum necessitatibus eius.
              </p>
            </div>
            <div className="col-lg-12 pb-5">
              <h2 className="title-heading mb-0">LOREM IPSUM</h2>
              <p className="mb-0 banner-description text-uppercase">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
                corrupti eum doloribus necessitatibus incidunt temporibus numquam
                fugit rem veniam, officiis obcaecati, maiores doloremque ut. Autem
                error a harum necessitatibus eius.
              </p>
            </div>
            <div className="col-lg-12">
              <h2 className="title-heading mb-0">LOREM IPSUM</h2>
              <p className="mb-0 banner-description text-uppercase pb-3">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
                corrupti eum doloribus necessitatibus incidunt temporibus numquam
                fugit rem veniam, officiis obcaecati, maiores doloremque ut. Autem
                error a harum necessitatibus eius.
              </p>
              <ul>
                <li className="text-white letter_spacing font-16">One</li>
                <li className="text-white letter_spacing font-16">One</li>
                <li className="text-white letter_spacing font-16">One</li>
                <li className="text-white letter_spacing font-16">One</li>
                <li className="text-white letter_spacing font-16">One</li>
                <li className="text-white letter_spacing font-16">One</li>
                <li className="text-white letter_spacing font-16">One</li>
                <li className="text-white letter_spacing font-16">One</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
