import React from "react";
import { useCallback, useEffect, useState } from "react";
import Selfmaximized from "../../src/images/admin-logo.png";
import DummyProductImage from "../../../web/src/images/dummy/card.png";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "../stripepayment/CheckoutForm";

// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const Checkout: React.FC = () => {

    const { id } = useParams();
    const [clientSecret, setClientSecret] = useState<any>(null);
    const [stripePromise, setStripePromise] = useState<any>(null);
    const [cartList, setCartList] = useState<any>([]);
    let data = localStorage.getItem('userInfo');
    let userInfo = null;
    if (typeof data === 'string') {
        try {
            userInfo = JSON.parse(data);
        } catch (error) {
            console.error('Error parsing userInfo:', error);
        }
    } else {
        userInfo = data;
    }
    let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;
    let cartId = localStorage.getItem("cartId");
    const token = localStorage.getItem('token');
    const location = useLocation();
    const buyNowCartId = location?.state;

    const getCartList = async () => {

        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "cartId":lastActiveCartId !== undefined && cartId ? cartId : cartId ? cartId :  lastActiveCartId,
            "organizationId": 1,
            "action": "command",
            "command": [
                {
                    "agent": "cartList",
                    "appName": "selfMaximized",
                    "folder": "order"
                }
            ]
        }, {
            headers: { 'x-access-token': token }
        })
        .then(result => {
            setCartList(result?.data?.data);
        })
        .catch(err => {
            console.log(err);
        })
    };

    const getCartListCb = useCallback(getCartList, [cartId, lastActiveCartId]);

    useEffect(() => {
        if(!buyNowCartId){
            getCartListCb();
        }
    }, [buyNowCartId, getCartListCb])

    // useEffect(() => {
    //     if (!cartId || !buyNowCartId) {
    //         navigate('/');
    //     }
    // }, [!cartId, !buyNowCartId])

    const getBuyNowCartList = async () => {
        if(buyNowCartId !== null){
            await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
                "cartId": buyNowCartId,
                "organizationId": 1,
                "action": "command",
                "command": [
                    {
                        "agent": "cartList",
                        "appName": "selfMaximized",
                        "folder": "order"
                    }
                ]
            }, {
                headers: { 'x-access-token': token }
            })
            .then(result => {
                setCartList(result?.data?.data);
            })
            .catch(err => {
                console.log(err);
            })
        }
    };

    const getBuyNowCartListCb = useCallback(getBuyNowCartList, [buyNowCartId]);

    useEffect(() => {
        getBuyNowCartListCb();
    }, [getBuyNowCartListCb])

    useEffect(() => {
            const fetchClientSecret = async () => {
                try {
                    const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
                        orderId: id,
                        cartId: buyNowCartId !== null ? buyNowCartId : lastActiveCartId !== undefined && cartId ? cartId : cartId ? cartId :  lastActiveCartId ,
                        organizationId: 1,
                        action: "command",
                        command: [
                            {
                                agent: "createCheckout",
                                appName: "selfMaximized",
                                folder: "payment",
                            },
                        ],
                    }, {
                        headers: { 'x-access-token': localStorage.getItem("token") }
                    });
    
                    if (result?.data?.code === 1) {
                        setClientSecret(result?.data?.data?.clientSecret);
                    } else {
                        console.error("Failed to fetch clientSecret:", result);
                    }
                } catch (error) {
                    console.error("Error fetching clientSecret:", error);
                }
            };
            fetchClientSecret();
    }, [id, buyNowCartId]);

    useEffect(() => {
        const initializeStripe = async () => {
            const stripeKey = process.env.REACT_APP_STRIPE_KEY;
            if (stripeKey) {
                const stripe = await loadStripe(stripeKey);
                setStripePromise(() => stripe);
            }
        };

        initializeStripe();
    }, []);

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#000000',
            colorBackground: '#000000',
            colorText: '#ffffff',
        },
    };
    const options: any = {
        clientSecret,
        appearance,
    };

    return (
        <>
            {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <div className="page-content pt-5 mt-5 ">
                        <section className="cart__page__wrapper py-0 cart__product__quantity__total pt-0 pt-lg-5">
                            <div className="container-fluid left-right-space">
                                <div className="row">
                                    <div className="col-lg-8 main">
                                        <div className="">
                                            {cartList.length !== 0 && cartList !== null ? cartList.map((elem: any) => (
                                                <div className="row d-flex align-items-center pb-5" key={elem.id}>
                                                    <div className="col-4">
                                                        <div className="card__product__image">
                                                            <img src={elem.profile === null ? elem.image : elem.profile} alt="" className="img-fluid w-100 card__product__image--image" style={{ width: 'auto', height: 'auto' }} loading="lazy" />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        {elem.packageType === 'Package' ? 
                                                            <div>
                                                                <h3 className="mb-0 buy__product__title">{elem.packageType.toUpperCase()} | {elem.title.toUpperCase()}</h3>
                                                                <p className="mb-0 buy__product__description pb-2"> {elem.packageDuration} HR VIRTUAL APPOINTMENT</p>
                                                            </div> : 
                                                            <div>
                                                                <h3 className="mb-0 buy__product__title">DR.{elem.name.toUpperCase()}</h3>
                                                                <p className="mb-0 buy__product__description pb-2">{elem.shorttitle.toUpperCase()} {elem.duration} VIRTUAL APPOINTMENT</p>
                                                            </div>
                                                        }
                                                        <div className="">
                                                            <p className="amount mb-0 text-center add__cart__amount">
                                                                <span className="dollar__symbol">$</span>
                                                                <span className="amt ps-2">{elem.price}{` (Tax :${elem.tax.slice(0,-3)}%) - `} <span className="dollar__symbol">$ </span>{`${elem.total}`}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <span>Cart list is empty</span>}
                                        </div>
                                    
                                    </div>
                                    <div className="col-lg-4 sidebar">
                                        <div className=" p-2">
                                            <CheckoutForm buyNowCartId={buyNowCartId}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Elements>
            )}
        </>
    );
}
export default Checkout


