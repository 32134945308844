import React, { createContext, useState } from "react";

interface AuthContextProps {
  cartLength: any;
  scrollCoordinates: any;
  scroll: (coordinates: any) => void;
  cart: (count: any) => void;
}

const AuthContext = createContext<AuthContextProps>({
  cartLength: "",
  scrollCoordinates: "",
  scroll: (coordinates: any) => {},
  cart: (count: any) => {},
});

export const AuthContextProvider = (props: any) => {
  const cartCnt = localStorage.getItem("cartCount");

  const [cartCount, setCartCount] = useState<string | null>(cartCnt);
  const [scrollCoordinates, setScrollCoordinates] = useState<any>("");

  function cartHandler(cartLength: any) {
    setCartCount(cartLength);
    localStorage.setItem("cartCount", cartLength);
  }

  function scrollHandler(scrollCoordinates: any) {
    setScrollCoordinates(scrollCoordinates);
  }

  const contextValue: AuthContextProps = {
    cartLength: cartCount,
    scrollCoordinates: scrollCoordinates,
    scroll: scrollHandler,
    cart: cartHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
