import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Careers = () => {
  const [activeIndex, setActiveIndex] = useState<any>("");

  const toggleAccordion = (index: any) => {
    setActiveIndex(activeIndex === index ? "" : index);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [careerList, setCareerList] = useState<any>([]);

  useEffect(() => {
    getCareerList();
  }, []);
  const getCareerList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        isActive: true,
        menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
        action: "command",
        command: [
          {
            agent: "careerList",
            appName: "selfMaximized",
            folder: "career",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCareerList(result?.data?.data);
        } else {
          setCareerList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  return (
    <>
      <section className="faq-wrapper">
        <div className="container-fluid left-right-space">
          <div className="row py-4">
            <div className="col-lg-12">
              <h1 className="text-center mb-0">
                CAREER
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {careerList.length !== 0 && careerList !== null ? (
                  careerList.map((elem: any, index: any) => (
                    <div className="accordion-item my-3">
                      <h2
                        className="accordion-header position-relative bg-white text-dark"
                        id="flush-headingOne"
                      >
                        <button
                          className={`accordion-button ${
                            activeIndex === index ? "" : "collapsed"
                          } bg-white`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={activeIndex === index}
                          aria-controls="flush-collapseOne"
                        >
                          {elem.title}
                        </button>
                        <span className="accordion-description_text d-block pb-2 text-uppercase">
                          {elem.summary}
                        </span>
                      </h2>
                      <div
                        id="flush-collapseOne open"
                        className={`accordion-collapse collapse ${
                          activeIndex === index ? "show" : ""
                        }`}
                        aria-labelledby="flush-headingOne"
                      >
                        <div className="accordion-body text-uppercase bg-white pt-0">{elem.description}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="text-uppercase">No points available</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
