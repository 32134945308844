import React, { useEffect, useState } from "react";
import ContactUsImage from "../../src/images/dummy/nutra-1.png";
import axios from "axios";
import { toast } from "react-toastify";
import ShimmerUI from "./Shimmer";

const ContactUs = () => {
  const [contactUsData, setContactUsData] = useState<any>([
    { name: "", email: "", message: "" },
  ]);
  const [puckLoader, setPuckLoader] = useState<any>(false);
  const [contactUsPuckData, setContactUsPuckData] = useState<any>("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [validationMessage, setValidationMessage] = useState<any>("");
  useEffect(() => {
    getContactUsPuckData();
  }, []);

  const handleContactUsData = (e: any) => {
    setContactUsData({ ...contactUsData, [e.target.name]: e.target.value });
  };

  const createContact = async () => {
    if (!contactUsData.name || !contactUsData.email || !contactUsData.message) {
      setValidationMessage(true);
      return;
    } else {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          name: contactUsData.name,
          email: contactUsData.email,
          message: contactUsData.message,
          menuId: "8dbf75a0-5a8e-48f5-9f17-701ccf5513ae",
          action: "command",
          command: [
            {
              agent: "createContact",
              appName: "selfMaximized",
              folder: "contact",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            setContactUsData({ name: "", email: "", message: "" });
            setValidationMessage(false);
            toast.success(
              "Message sent successfully! Our team will revert to you as soon as possible."
            );
          } else {
            return;
          }
        })

        .catch((err) => {
          return toast.error("Something went wrong", err);
        });
    }
  };

  const getContactUsPuckData = async () => {
    setPuckLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "CONTACT US",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setContactUsPuckData(data);
          setPuckLoader(false);
        } else {
          setContactUsPuckData([]);
          setPuckLoader(false);
        }
      })
      .catch((err) => {
        setPuckLoader(false);
        return err;
      });
  };

  const contactUs = contactUsPuckData?.content?.find(
    (elem: any) => elem.type === "AboutUs"
  );

  let revokeBlobUrl: any;

  if (contactUs) {
    revokeBlobUrl = contactUs?.props?.contactUsImage;
  }

  return puckLoader && contactUsPuckData.length === 0 ? (
    <ShimmerUI />
  ) : (
    <>
      <section className="pb-0 user-registration-flow-wrapper contact-us_wrapper_title">
        <div className="container-fluid left-right-space">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="user-registration-title mb-0 text-center my-4">
                CONTACT US
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className=" pb-0 user-registration-flow-wrapper py-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 px-0">
              <div className="contact-us-image">
                <img
                  src={revokeBlobUrl ?? ContactUsImage}
                  alt=""
                  className="img-fluid w-100"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-0 custom-pt-1 pb-0 user-registration-flow-wrapper pt-0 contact-us-wrapper_form">
        <div className="container-fluid left-right-space">
          <div className="col-lg-12 text-center">
            <div className="">
              <h3 className="text-white letter_spacing">
                {contactUs?.props?.contactUsHeading
                  ? contactUs?.props?.contactUsHeading
                  : null}
              </h3>
              <p className="mb-0 contact-us-description">
                {contactUs?.props?.contactUsText
                  ? contactUs?.props?.contactUsText
                  : null}
              </p>
            </div>
            <div className="">
              <p className="mb-0 contact-us-description">
                <a
                  href={`mailto:${contactUs?.props?.selfMaximizedEmail}`}
                  className="helpEmail text-white cursor_pointer"
                  style={{ color: "white" }}
                >
                  {contactUs?.props?.selfMaximizedEmail
                    ? contactUs?.props?.selfMaximizedEmail
                    : null}
                </a>
              </p>
            </div>
          </div>
          <div className="divider"></div>
          <div className="col-lg-12 contact-us-form-wrapper">
            <form className="">
              <div className="row">
                <div className="col-lg-12 pb-3">
                  <label htmlFor="fname">
                    <span className="text-white pe-1 vertical-top">*</span>
                    {contactUs?.props?.contactUsName
                      ? contactUs?.props?.contactUsName
                      : "NAME"}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={contactUsData.name}
                    autoComplete="off"
                    onChange={handleContactUsData}
                  />
                  <span className="text-danger letter-spacing mt-2">
                    {validationMessage &&
                      !contactUsData.name &&
                      "Please enter your name"}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 pb-3">
                  <label htmlFor="email">
                    <span className="text-white pe-1 vertical-top">*</span>
                    {contactUs?.props?.contactUsEmail
                      ? contactUs?.props?.contactUsEmail
                      : "EMAIL"}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={contactUsData.email}
                    autoComplete="off"
                    onChange={handleContactUsData}
                  />
                  <span className="text-danger letter-spacing mt-2">
                    {(validationMessage &&
                      !contactUsData.email &&
                      "Please enter your email") ||
                      (validationMessage &&
                        !emailRegex.test(contactUsData.email) &&
                        "Please enter a valid email address")}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 pb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      <span className="text-white pe-1 vertical-top">*</span>
                      {contactUs?.props?.contactUsMessage
                        ? contactUs?.props?.contactUsMessage
                        : "MESSAGE"}
                    </label>
                    <textarea
                      className="form-control text-white letter_spacing"
                      id="contact-us-textarea"
                      name="message"
                      value={contactUsData.message}
                      rows={4}
                      cols={5}
                      onChange={handleContactUsData}
                    />
                    <span className="text-danger letter-spacing mt-2">
                      {validationMessage &&
                        !contactUsData.message &&
                        "Please enter your query."}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row pb-3">
                <div className="col-lg-12 text-end position-relative">
                  <button
                    className="next-btn"
                    type="button"
                    onClick={createContact}
                  >
                    <span className="">
                      {contactUs?.props?.submitButton
                        ? contactUs?.props?.submitButton
                        : "SUBMIT"}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
