import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import AuthContext from "../UseContext";

interface PackageUUID {
    packageUuid: number,
}
const PackageSpecialistAppointment: React.FC<PackageUUID> = ({ packageUuid }) => {

    const [packageDetails, setParticularPackageDetails] = useState<any>([]);
    const [packageCategory, setPackageCategories] = useState<any>([]);
    const [specialistList, setSpecialistList] = useState<any>([]);
    const [doctorId, setDoctorId] = useState<any>(null);
    const [doctor, setDoctorName] = useState<any>({});
    const [doctorArray, setDoctorArray] = useState<any>([]);
    const [categoryName, setCategoryName] = useState<any>(null);
    const [ipAddress, setIpAddress] = useState<any>('');
    const [cartId, setCartId] = useState<any>("");
    const [token, setToken] = useState<any>('');
    const [slots, setSlots] = useState<any>([]);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [dayName, setDayName] = useState<any>('');
    const [selectedSlot, setSelectedSlot] = useState<any>('');
    const [categoryID, setCategoryId] = useState<any>('');
    const [metaDataArray, setMetaDataArray] = useState<any>([]);
    const [productId, setProductId] = useState<any>('');
    const [drName, setDrName] = useState<any>('');
    const [drTitle, setDrTitle] = useState<any>('');
    const currentDate = new Date();
    const navigate = useNavigate();

    useEffect(() => {
        setCategoryId('');
        setSelectedDate(null);
        setSelectedSlot('');
        setDoctorArray([]);
        setCategoryName(null);
        setDoctorName({});
    }, [packageUuid]);

    const handleDateChange = async (date: any) => {
        // Use moment.js to properly handle date formatting and timezone issues
        const formattedDate = moment(date).format('YYYY-MM-DD');

        // Update state with the formatted date
        setDayName(moment(date).format('dddd'));
        setSelectedDate(formattedDate);

        // Pass the formatted date to getSpecialistBookingSlots        
        await getSpecialistBookingSlots(formattedDate);
    };

    const passSlots = (selectedSlot: any) => {
        console.log('Worked Fine 1 ')
        setSelectedSlot({ 'category': categoryID, selectedSlot });
        console.log('Worked Fine 2')
    }

    useEffect(() => {
        if (selectedSlot !== null && selectedSlot !== undefined && Object.keys(selectedSlot).length !== 0) {
            setMetaDataArray((prevState: any) => [
                ...prevState.filter((elem: any) => elem.category !== categoryID),
                selectedSlot
            ]);
        }
    }, [selectedSlot]);

    const particularPackageDetails = async () => {

        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "filter": {
                "filter": {
                    "logic": "and",
                    "offset": 0,
                    "limit": 1,
                    "filters": [
                        {
                            "field": "publish",
                            "operator": "eq",
                            "value": "1"
                        },
                        {
                            "field": "uuid",
                            "operator": "eq",
                            "value": packageUuid
                        }
                    ],
                    "sort": [
                        {
                            "field": "id",
                            "dir": "desc"
                        }
                    ]
                }
            },
            "action": "command",
            "command": [
                {
                    "agent": "packageList",
                    "appName": "selfMaximized",
                    "folder": "packages"
                }
            ]
        })
            .then(result => {
                if (result?.data?.code === 1) {
                    setParticularPackageDetails(result?.data?.data[0]);
                    setPackageCategories(result?.data?.data[0].category);
                } else {
                    return toast.info(result?.data?.message);
                }
            })
            .catch(err => {
                return toast.error("Something went wrong", err)
            })

    }

    const particularPackageDetailsCB = useCallback(particularPackageDetails, [packageUuid]);

    useEffect(() => {
        particularPackageDetailsCB();
    }, [particularPackageDetailsCB])



    const selectCategory = async (categoryData: any, categoryId: any) => {
        setCategoryName(categoryData);
        setDoctorId(null);
        setCategoryId(categoryId);
        setSelectedSlot('');
        setSelectedDate('');
        setSlots([]);
        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "filter": {
                "filter": {
                    "logic": "and",
                    "offset": 0,
                    "limit": 50,
                    "filters": [
                        {
                            "field": "uuid",
                            "operator": "eq",
                            "table": "Categories",
                            "value": categoryData.uuid
                        },
                        {
                            "field": "duration",
                            "operator": "eq",
                            "table": "Product",
                            "value": packageDetails.duration
                        },
                        {
                            "field": "isActive",
                            "operator": "eq",
                            "table": "Product",
                            "value": 1
                        }
                    ],
                    "sort": [
                        {
                            "field": "id",
                            "dir": "desc"
                        }
                    ]
                }
            },
            "menuId": "a2039418-bbd0-4b25-b314-7b5af09b8937",
            "action": "command",
            "command": [
                {
                    "agent": "productListDrExp",
                    "appName": "selfMaximized",
                    "folder": "specialist"
                }
            ]
        })
            .then(result => {
                if (result?.data?.code === 1) {
                    setSpecialistList(result?.data?.data);
                    // setProductId(result?.data?.data[0].id);
                } else {
                    setSpecialistList([]);
                }
            })
            .catch(err => {
                return toast.error('Something went wrong', err);
            })
    };

    const handleCheckboxChange = (id: any, doctor_id: any, doctorName: any, doctorTitle: any) => {
        setDoctorId(doctor_id);
        setProductId(id);
        setSlots([]);
        setSelectedDate('');
        setDrName(doctorName);
        setDrTitle(doctorTitle);
        // setDoctorName({ [categoryName]: { 'category': categoryName.name, 'name': doctorName, 'title': doctorTitle , 'slot' : selectedSlot} });
    };

    useEffect(() => {
        if (selectedSlot.selectedSlot) {
            const startTime = selectedSlot.selectedSlot.StartTime;
            const endTime = selectedSlot.selectedSlot.EndTime;
            setDoctorName({ [categoryName]: { 'category': categoryName.name, 'name': drName, 'title': drTitle, 'slot': `${startTime} - ${endTime}` } });
        }

    }, [selectedSlot]);

    useEffect(() => {
        if (doctor[categoryName]) {
            const { category } = doctor[categoryName];
            setDoctorArray((prevState: any) => [...prevState.filter((elem: any) => elem.category !== category), doctor[categoryName]])
        }
    }, [categoryName, doctor, selectedSlot]);

  
    let data = localStorage.getItem('userInfo');
    let userInfo = null;
    if (typeof data === 'string') {
        try {
            userInfo = JSON.parse(data);
        } catch (error) {
            console.error('Error parsing userInfo:', error);
        }
    } else {
        userInfo = data;
    }

    let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;

    const goToAddCartPage = async () => {
        const cartIdLS = localStorage.getItem('cartId');
        const tokenLS = localStorage.getItem('token');
        setToken(tokenLS);
        setCartId(cartIdLS);
        if(lastActiveCartId){
            await addProductToCart("", "");
        }
        if (cartIdLS && !lastActiveCartId) {
            await addProductToCart(cartIdLS, "");
        }
        
        if(!cartIdLS && !lastActiveCartId){
            await createCart();
        };
    };

    const getSpecialistBookingSlots = async (selectedDate: any) => {

        const tokenLS = localStorage.getItem('token');
        setToken(tokenLS);
        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "filter": {
                "filter": {
                    "logic": "and",
                    "offset": 0,
                    "limit": 500,
                    "filters": [
                        {
                            "field": "calendarDate",
                            "operator": "eq",
                            "table": "BookingCalendar",
                            "value": selectedDate
                        },
                        {
                            "field": "doctorId",
                            "operator": "eq",
                            "table": "BookingCalendar",
                            "value": doctorId
                        },
                        {
                            "field": "Duration",
                            "operator": "eq",
                            "table": "BookingCalendar",
                            "value": packageDetails.duration
                        }
                    ],
                    "sort": [
                        {
                            "field": "calendarDate",
                            "table": "BookingCalendar",
                            "dir": "desc"
                        }
                    ]
                }
            },
            "action": "command",
            "command": [
                {
                    "agent": "specialistCalendarBooking",
                    "appName": "selfMaximized",
                    "folder": "order"
                }
            ]
        }, {
            headers: { 'x-access-token': token }
        })
            .then(result => {
                if (result?.data?.code === 1) {
                    setSlots(result?.data?.data?.response);
                } else {
                    return toast.error('Something went wrong')
                }
            })
            .catch(err => {
                return toast.error('Something went wrong', err)
            })
    };

    const metaData: any = [];
    metaDataArray.map((elem: any) => {
        metaData.push({
            "productId": productId,
            "doctorId": doctorId,
            "StartTime": elem.selectedSlot.StartTime,
            "EndTime": elem.selectedSlot.EndTime,
            "Duration": elem.selectedSlot.Duration,
            "Day": elem.selectedSlot.calendarDay,
            "Date": selectedDate,
            "categoryid": elem.category
        })
    });

 

    const addProductToCart = async (cartIdLS: any, createdCartID: any) => {
        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "cartId": lastActiveCartId !== undefined ? lastActiveCartId : createdCartID || cartIdLS ,
            "packageId": packageUuid,
            "ip": ipAddress,
            "qty": 1,
            "organizationId": 1,
            "packageType": "Package",
            "metadata": metaData,
            "action": "command",
            "command": [
                {
                    "agent": "packageAddToCart",
                    "appName": "selfMaximized",
                    "folder": "order"
                }
            ]
        }, {
            headers: { 'x-access-token': token }
        })
            .then(result => {
                if (result?.data?.data.length === 0) {
                    return toast.info(result.data.message);
                } else {
                    toast.success('Item added to cart');
                    navigate('/cart');
                }
            })
            .catch(err => {
                return toast.error(err)
            })
    }

    const createCart = async () => {
        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "ip": ipAddress,
            "action": "command",
            "command": [
                {
                    "agent": "createCart",
                    "appName": "selfMaximized",
                    "folder": "order"
                }
            ]
        }, {
            headers: { 'x-access-token': token }
        })
            .then(async result => {
                if (result?.data?.code === 1) {
                    setCartId(result?.data?.data?.cartId);
                    localStorage.setItem('cartId', result?.data?.data?.cartId);
                    if (result?.data?.data?.cartId) {
                        await addProductToCart('', result?.data?.data?.cartId);
                    };
                } else {
                    return toast.info('Please login first before adding the product to your cart');
                }
            })
            .catch(err => {
                return toast.error("Something went wrong", err)
            })
    }

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setIpAddress(data.ip);
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
            });
    }, []);

    const deleteItem = (id: any) => {
        setDoctorArray((prevState: any) => [...prevState.filter((elem: any, index: any) => index !== id)])

    }

    return (

        <div className="weight-loss-package-content">
            <section className="pt-0">
                <div className="row blog_page_gallery position-relative">
                    <div className="col-lg-12 position-relative px-2 px-md-0">
                        <div className="journal-box">
                            <div className="journal-thumb">
                                <div className="cs-image">
                                    <img src={packageDetails.image} alt="journal"
                                        className="img-fluid w-100 main-img"
                                        style={{ width: 'auto', height: '520px' }} loading="lazy" />
                                </div>
                            </div>
                            <div className="journal-content">
                                <p className="journal-date-title mb-0">{packageDetails.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="singular-appoinment py-5">
                <div className="container-fluid left-right-space">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="mb-0 singular-appoinment pb-1">SINGULAR APPOINMENT</p>
                            <p className="mb-0 singular-desc pb-4 text-uppercase">Select any one product from the above products!</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 pb-3">
                            <div className="row">
                                <div>
                                    <div
                                        className="d-flex justify-content-between align-items-center appoinment_status py-2 px-3">
                                        <div className="">
                                            <p className="mb-0 virtula-appoinment-text">{packageDetails.summary} | {packageDetails.duration} HR</p>
                                        </div>
                                        <div className="">
                                            <div className="package-amount-in-dollar px-3 py-1">
                                                <div className="d-flex align-items-center">
                                                    <span className="pe-1"><i
                                                        className="fa-solid fa-dollar-sign"></i></span>
                                                    <span className="amount-dollar_package">{packageDetails.price}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <section className="wellness-category-wrapper pb-0">
                <div className="container-fluid left-right-space">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="mb-0 wellness-categeory-text">WELLNESS CATEGORY - UNDER THIS PACKAGE</p>
                        </div>
                        <div className="col-lg-12 ">
                            <p className="text-white letter_spacing text-uppercase">NOTE : Click on the category and select specialist accordingly. You can select only one specialist for one category</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid px-0">
                    <div className="row">
                        {packageCategory.length !== 0 ? packageCategory.map((elem: any) => (
                            <div className={`col-lg-3 ps-0 pb-3 ps-3`} key={elem.id} onClick={() => selectCategory(elem, elem.id)}>
                                <div className={`${categoryID === elem.id ? 'package--active' : null} position-relative`}>
                                    <div className="thumb">
                                        <img src={elem.image} alt="pregency"
                                            className="img-fluid w-100" style={{ width: 'auto', height: '250px' }} loading="lazy" />
                                    </div>
                                    <div className="content">
                                        <p className="mb-0 wellness-cat-text">{elem.name}</p>
                                    </div>
                                </div>
                            </div>
                        )) :
                            <div className="col-lg-12">
                                <span className="no__product__available text-uppercase">No category available</span>
                            </div>
                        }

                    </div>
                </div>
            </section>

            {categoryID ? <section className="doctord_under_the_catageory">
                <div className="container-fluid left-right-space">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="mb-0 wellness-categeory-text">DOCTORS - UNDER THIS CATEGORY
                            </p>
                            <p className="mb-0 please-choose_catageory pb-3">Please choose the expert in
                                this category so we
                                can move forward with the next steps.</p>
                        </div>
                    </div>
                    <div className="row">
                        {doctorArray.length !== 0 && doctorArray !== null ? doctorArray.map((elem: any, index: any) => (
                            <div className="col-lg-3 col-md-4 pb-3 pb-lg-0 mb-3" key={elem.category}>
                                <div
                                    className="catageory-doctors d-flex align-items-center justify-content-between p-2">
                                    <p className="mb-0 doctor-categore_name">DR. {elem.name.toUpperCase()} | {elem.title.toUpperCase()} | {elem.category} | {elem.slot}</p>
                                    <i className="fa-solid fa-xmark" onClick={() => deleteItem(index)}></i>
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </section> : null}

            {categoryID ? <section className="doctors-wrapper_packages py-0">
                <div className="container-fluid px-0">
                    <div className="row">
                        {specialistList.length !== 0 && specialistList !== null ? specialistList.map((elem: any) => (
                            <div className="col-lg-6 px-2 px-md-0 position-relative" key={elem.id} >
                                <div className="explore_more_wrapper_one-doctors">
                                    <div className="explore-thumb">
                                        <img src={elem.profile} alt="pregency"
                                            className="img-fluid w-100 object-fit-cover"
                                            style={{ width: 'auto', height: '500px' }} loading="lazy" />
                                    </div>
                                    <div className="hidden-content-when-hover-wrapper_one">
                                        <h3 className="explore-heading mb-0">{elem.name.toUpperCase()}</h3>
                                        <p className="mb-0 specialst_degree">{elem.DoctorExperiences[0].title.toUpperCase()}</p>
                                        <p className="mb-0 specialist-gender">{elem.DoctorExperiences[0].gender}</p>
                                        <p className="mb-0 specialist-experties">{elem.DoctorExperiences[0].areaofexpertise}</p>
                                        <p className="specialist-year-of-experience mb-0">{elem.DoctorExperiences[0].yearofexperience}</p>
                                        <p className="mb-0 specialist-langauge">{elem.DoctorExperiences[0].language}</p>
                                    </div>
                                    <div className="checkbox_wrapper" >
                                        <div className="checkboxOverride">
                                            <input type="checkbox" id="checkboxInputOverride1" checked={doctorId === elem.id}
                                            />
                                            <label htmlFor="checkboxInputOverride1" id="openModal2" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleCheckboxChange(elem.id, elem.doctorid, elem.name, elem.DoctorExperiences[0].title)}></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) :
                            <div className="left-right-space">
                                <div className="col-lg-12 ps-2 text-center">
                                    <span className="no__product__available text-white">No Data Available</span>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </section> : null}

            {categoryID ? <section className="revolution-in-holistic py-5 ">
                <div className="container-fluid left-right-space text-end">
                    <div className="row d-flex justify-content-end">
                        <div className="d-none d-lg-block ">
                            <button className="buy__now  buy__btn">BUY NOW</button>
                            <button className="procced__to__buy ms-2 buy__btn" onClick={goToAddCartPage}>ADD TO CART</button>
                        </div>
                        {/* Mobile BUY NOW and ADD TO CART */}
                        <div className="d-block d-lg-none">
                            <button className="buy__now  buy__btn w-100 mb-3">BUY NOW</button>
                            <button className="procced__to__buy buy__btn w-100" onClick={goToAddCartPage}>ADD TO CART</button>
                        </div>
                    </div>

                </div>
            </section> : null}

            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered user__calander__booking--list modal-lg">
                    <div className="modal-content buy__product__modal">
                        <div className="modal-header py-2">
                            {/* <p className="available-slots-doctor mb-0">Available Slots: DR.{specialistName.toUpperCase()}</p> */}
                            <div className="available__slots__days__week d-flex align-items-center">
                                <span className="text-white days__in__number">07</span> <span className="week--text ps-2">daya a week</span>
                            </div>

                        </div>
                        <div className="modal-body">
                            <p className="mb-0 please__select__time__slot pb-3">Please select your time slot before we process</p>
                            <div>
                                <DatePicker
                                    className="available-date-set"
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    minDate={currentDate}
                                    dateFormat="EEEE, yyyy/MM/dd" // customize date format
                                    placeholderText="SELECT DATE"
                                />
                            </div>
                            <div className="row pt-3">
                                {slots.length !== 0 && slots !== null ? slots.map((elem: any) => (
                                    <div className={` col-lg-3 col-md-6 col-sm-3 col-6 pb-3`} onClick={() => { return elem.isBooked === 1 ? false : passSlots(elem) }} key={elem.id} >
                                        <div className={`${elem.isBooked === 0 ? 'available__time__duration' : 'booked_slot'} p-2 ps-3 ${selectedSlot.selectedSlot === elem ? 'white_color' : 'text-dark'}`}>
                                            <span className="available__time--duration d-block">{elem.isBooked === 0 ? 'Available' : 'Booked'}</span>
                                            <span className={`d-block available__time ${selectedSlot.selectedSlot === elem ? 'text-dark' : 'text-white'}`}>{elem.StartTime} - {elem.EndTime}</span>
                                            <span className={`duration  ${selectedSlot.selectedSlot === elem ? 'text-dark' : 'text-white'}`}>{elem.Duration} HR</span>
                                        </div>
                                    </div>
                                )) : <span className="no__slots--available text-center">No slots available</span>}
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-end">
                                    <button className="procced__to__buy ms-2 buy__btn" data-bs-dismiss="modal">BOOK</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackageSpecialistAppointment;