import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { toast } from "react-toastify";

interface SpecialistProductsProps {
    userUUID: string;
}

const SpecialistProducts: React.FC<SpecialistProductsProps> = ({ userUUID }) => {

    const [specialistProducts, setSpecialistProducts] = useState<any>([]);
    const [ipAddress, setIpAddress] = useState<any>('');
    const [cartId, setCartId] = useState<any>('');


    const navigate = useNavigate();

    const specialistProductList = async () => {
        await axios.post(`${process.env.REACT_APP_API}/pipeline`,{
            "filter": {
                "filter": {
                    "logic": "and",
                    "offset": 0,
                    "limit": 50,
                    "filters": [
                        {
                            "field": "uuid",
                            "operator": "eq",
                            "table": "Users",
                            "value": userUUID
                        }
                    ],
                    "sort": [
                        {
                            "field": "id",
                            "dir": "desc"
                        }
                    ]
                }
            },
            "menuId": "a2039418-bbd0-4b25-b314-7b5af09b8937",
            "action": "command",
            "command": [
                {
                    "agent": "productList",
                    "appName": "selfMaximized",
                    "folder": "specialist"
                }
            ]
        })
        .then(result => {
            setSpecialistProducts(result?.data?.data);
            console.log(result);
        })
        .catch(err => {
            console.log(err)
        })
    };
    

    const goToAddToCartPage = async () => {

        const userRole = localStorage.getItem('userRole');
        const token = localStorage.getItem('token');

    
        if (!userRole && !token) {
            navigate('/app/login?callback=/cart', {state : 'user'});
            window.location.reload();
            return;
        }
    
        const decryptUserRole = (encryptedRole : any, key : any) => {
            const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
            return bytes.toString(CryptoJS.enc.Utf8);
        };
    
        const decryptedRole = decryptUserRole(userRole, '98760'); // Replace '98760' with your actual key
        
        const cartIdlocalStorage = localStorage.getItem('cartId');
        if(!cartIdlocalStorage){
            await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
                "ip": ipAddress,
                "action": "command",
                "command": [
                    {
                        "agent": "createCart",
                        "appName": "selfMaximized",
                        "folder": "order"
                    }
                ]
            },{
                headers: { 'x-access-token': token }
            })
            .then(result => {
                if(result?.data?.code === 1){
                    setCartId(result?.data?.data?.response?.cartId);
                    localStorage.setItem('cartId', result?.data?.data?.response?.cartId);
                    if (decryptedRole === '"user"') {
                        navigate('/cart', {state: userUUID});
                    }
                }else{
                    toast.error('Error in generating cart id');
                    return
                }
            })
        }else if(decryptedRole === '"user"' && cartIdlocalStorage){
            navigate('/cart', {state: userUUID});
        }else{
            navigate('/app/login', {state: 'user'})
            window.location.reload();
            return
        }
    };
    

    const specialistProductListCB = useCallback(specialistProductList, [userUUID]);

    

    useEffect(() => {
        specialistProductListCB();
    },[]);


    useEffect(() => {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          setIpAddress(data.ip);
        })
        .catch(error => {
          console.error('Error fetching IP address:', error);
        });
    }, []);

    console.log(ipAddress);

    return (
        <>
            <section className="singular-appoinment">
                <div className="container-fluid left-right-space">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="mb-0 singular-appoinment pb-1">SINGULARA
                                APPOINMENT</p>
                            <p className="mb-0 singular-desc pb-4">Lorem, ipsum dolor
                                sit amet consectetur
                                adipisicing elit.
                                Unde
                                reiciendis numquam ex atque! Aspernatur, illo!</p>
                        </div>
                    </div>
                    <div className="row">
                        {specialistProducts.length !== 0 && specialistProducts !== null ? specialistProducts.map((elem : any) => (
                            <div className="col-lg-6 pb-3">
                                <div className="row">
                                    <div>
                                        <div
                                            className="d-flex justify-content-between align-items-center appoinment_status py-2 px-3">
                                            <div className="">
                                                <p className="mb-0 virtula-appoinment-text">
                                                   {elem.shorttitle}
                                                </p>
                                            </div>
                                            <div className="">
                                                <div
                                                    className="package-amount-in-dollar px-3 py-2">
                                                    <div className="d-flex align-items-center">
                                                        <span className="pe-1">
                                                            <i className="fa-solid fa-dollar-sign"></i>
                                                        </span>
                                                        <span
                                                            className="amount-dollar_package">{elem.price}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) 
                         : <span className="text-white">No Products Available</span>}
                       
                       

                        <div className="col-lg-12 text-end pt-2 pt-lg-0">
                            <button  className="next-btn"
                             onClick={goToAddToCartPage}>ADD TO CART</button>
                            <div className="modal fade" id="exampleModalSpecialist"
                                tabIndex={-1} aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">

                                        <div className="modal-body text-center my-5 py-5">
                                            <div className="spinner">
                                                <div className="bar1"></div>
                                                <div className="bar2"></div>
                                                <div className="bar3"></div>
                                                <div className="bar4"></div>
                                                <div className="bar5"></div>
                                                <div className="bar6"></div>
                                                <div className="bar7"></div>
                                                <div className="bar8"></div>
                                                <div className="bar9"></div>
                                                <div className="bar10"></div>
                                                <div className="bar11"></div>
                                                <div className="bar12"></div>
                                            </div>
                                            <p className="mb-0 you-will-receive">
                                                THANKS FOR YOUR RESPONSE
                                            </p>
                                            <h3 className="mb-0 thanks-for-your-response">
                                                REDIRECTING YOU TO PAYMENT GATEWAY</h3>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};


export default SpecialistProducts;