import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';
import singlGrid from "../../../web/src/images/grid/Two-grid.png";
import twoGrid from "../../../web/src/images/grid/four-grid.png";
import useDebounce from "../customHooks/useDebounce";



const Specialist: React.FC = () => {


    const [specialistList, setSpecialistList] = useState<any>([]);
    const [specialistFilter, setSpecialistFilter] = useState<any>(['Gender', "Languages Spoken", "Year Of Experience", "Rating"])
    const [filterType, setFilterType] = useState<any>('');
    const [filterValue, setFilterValue] = useState<any>('');
    const [filterMenu, setFilterMenu] = useState<any>('');
    const [gridType, setGridType] = useState<any>('');
    const [categoryList, setCategoryList] = useState<any>([]);
    const [calledOnce, setCalledOnce] = useState<any>(false);
    const debouncedFilterValue = useDebounce(filterValue, 400);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getSpecialistList = async () => {
        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "filter": {
                "filter": {
                    "logic": "and",
                    "filters": [
                        {
                            "field": "isActive",
                            "operator": "eq",
                            "value": "1"
                        },
                        {
                            "field": filterType === 'Languages Spoken' ? 'language' : filterType === 'Year Of Experience' ? 'yearofexperience' : filterType === 'Gender' ? 'gender' : 'rating',
                            "operator": filterType === 'Languages Spoken' ? "contain" : "eq",
                            "table": filterType === 'Year Of Experience' ? "DoctorExperiences" : "Doctors",
                            "value": filterValue
                        }
                    ],
                    "sort": [
                        {
                            "field": "id",
                            "dir": "desc"
                        }
                    ]
                }
            },
            "menuId": "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
            "action": "command",
            "command": [
                {
                    "agent": "specialistLists",
                    "appName": "selfMaximized",
                    "folder": "specialist"
                }
            ]
        })
            .then(result => {
                if (result?.data?.code === 1) {
                    setSpecialistList(result?.data?.data);
                } else {
                    toast.error('Something went wrong');
                }
            })
            .catch(err => {
                return toast.error('Something went wrong' + err);
            })
    };

    const userRole = localStorage.getItem('userRole');
    const token = localStorage.getItem('token');

    let decryptedRole: any;

    if (userRole && token) {
        const decryptUserRole = (encryptedRole: any, key: any) => {
            const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
            return bytes.toString(CryptoJS.enc.Utf8);
        };

        decryptedRole = decryptUserRole(userRole, '98760');
    }

    useEffect(() => {
        getSpecialistList();
    }, []);

    const goToBuyProductPage = (doctorUserUUID: any) => {
        if (decryptedRole === '"user"') {
            navigate(`/buyproduct/${doctorUserUUID}/0`, { state: doctorUserUUID });
            return
        } else if (decryptedRole && decryptedRole !== '"user"') {
            return toast.info("Register as an user first!")
        } else {
            window.location.href = `/app/login?callback=/buyproduct/${doctorUserUUID}/0`;
            return
        }
    };

    const checkFilter = (filterType: any) => {
        setFilterType(filterType);
        setFilterValue('');
        setFilterMenu(false);
    };

    const handleFilter = (event: any) => {
        setFilterValue(event.target.value);
    };


    const toggleFilterMenu = () => {
        setFilterMenu(!filterMenu);
        setFilterType('');
        setFilterValue('');
    };

    const handleGrid = (gridType: any) => {
        setGridType(gridType);
    };

    useEffect(() => {
        if (filterValue && filterType) {
            getSpecialistList();
        }
    }, [debouncedFilterValue]);


    const getCategoryList = async () => {
        await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "filter": {
                "filter": {
                    "logic": "and",
                    "offset": 0,
                    "limit": 50,
                    "sort": [
                        {
                            "field": "id",
                            "dir": "desc"
                        }
                    ]
                }
            },
            "menuId": "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
            "action": "command",
            "command": [
                {
                    "agent": "categoryListsMostBooked",
                    "appName": "selfMaximized",
                    "folder": "category"
                }
            ]
        })
            .then(result => {
                if (result?.data?.code === 1) {
                    setCategoryList(result?.data?.data);
                } else {
                    setCategoryList([]);
                }
            })
            .catch(err => {
                return toast.error("Something went wrong", err);
            })
    };

    useEffect(() => {
        function handleScroll() {
            const scrollHeight = document.body.scrollHeight;
            const scrollTop = window.scrollY;
            const clientHeight = window.innerHeight;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

            if (scrollPercentage >= 0.5 && !calledOnce) {
                getCategoryList();
                setCalledOnce(true);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [calledOnce]);

    return (

        <>
            <section className="custom-pt-1 custom-pb-2 mobile__custom--pb-2 parallaxie position-relative journal-detail-page-wrapper h-100"
                data-overlay="6">
                <div className="container-fluid position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="detail-page-banner-inner">
                                <h1 className="mb-0 banner-heading">SPECIALIST</h1>
                                <p className="mb-0 banner-description">
                                    Medcity has been present in Europe since 1990, offering innovative solutions,
                                    specializing
                                    in medical services for treatment of medical infrastructure.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="page-content">
                <section className="more-for-you py-0 mb-4">
                    <div className="container-fluid left-right-space">
                        <div className="row pb-5">
                            <div className="col-lg-12">
                                <h2 className="mb-0 more-for-you-heading">SELECT WELLNESS CATAGORY
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row no-gutters portfolio_page_gallery">
                            {categoryList.length !== 0 && categoryList !== null ? categoryList.map((elem: any) => (
                                <div className="col-lg-6 position-relative px-0">
                                    <NavLink to={`/categories/${elem.id}/${elem.uuid}`} className="journal-box">
                                        <div className="journal-thumb">
                                            <div className="cs-image">
                                                <img src={elem.image} alt="journal"
                                                    className="img-fluid w-100 main-img" style={{ width: 'auto', height: '520px' }} loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="journal-content">
                                            <p className="journal-date-title-header mb-0">{elem.name.toUpperCase()}</p>
                                        </div>
                                    </NavLink>
                                </div>
                            )) :

                                <div className="col-lg-12">
                                    <span className="d-block text-center letter_spacing text-white">No categories available</span>
                                </div>

                            }
                        </div>
                    </div>
                </section>
                <section className="grid_layout_icon pb-4 pt-0">
                    <div className="container-fluid left-right-space">
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-end">
                                <div className="grid-icos">
                                    <img src={singlGrid} alt="two grid" className="img-fluid me-2 cursor_pointer"
                                        style={{ width: '20px', height: '20px' }} loading="lazy" onClick={() => handleGrid('col-lg-6')} />
                                    <img src={twoGrid} alt="two grid" className="img-fluid cursor_pointer"
                                        style={{ width: '20px', height: '20px' }} loading="lazy" onClick={() => handleGrid('col-lg-3')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="search-bar-with-dropdown py-0">
                    <div className="contaier-fluid left-right-space">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="header__search w-100">
                                        <form className="form-search" action="#" method="GET">
                                            <div className="form-search__container">
                                                <span className="form-search__icon-left">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                                <input className="form-search__input" type="text" placeholder={`SEARCH BY ${filterType ? filterType.toUpperCase() : ''}`} value={filterValue} onChange={handleFilter} />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="filter_dropdown ps-1">
                                        <button type="button" className="images-drop-down d-none d-md-block">
                                            <button className="filter__btn" onClick={toggleFilterMenu}>
                                                <i className="fas fa-filter" ></i>
                                            </button>
                                            <div className="Images animated fadeIn mt-2 container position-absolute" style={{ display: `${filterMenu ? 'block' : 'none'}` }}>
                                                <div className="row d-flex align-items-center text-start pb-3">
                                                    <div className="col-lg-12">
                                                        <p className="mb-0 sort-by-text">Filter by</p>
                                                    </div>
                                                    <div className="col-8"></div>
                                                </div>
                                                {specialistFilter.map((elem: any) => (
                                                    <div
                                                        className="row d-flex align-items-center justify-content-start filter_sort_wrapper" key={elem}>
                                                        <div className="col-12 filtter_btween text-start">
                                                            <input className="form-check-input" type="checkbox" id="v-pills-home-tab" onClick={() => checkFilter(elem)} checked={filterType === elem} />
                                                            <label className="form-check-label mb-0 ps-2" htmlFor="flexCheckDefault">
                                                                {elem}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </button>
                                        <button className="images-drop-down d-block d-md-none" type="button"
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                                            aria-controls="offcanvasRight"><i className="fas fa-filter"></i></button>

                                        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight"
                                            aria-labelledby="offcanvasRightLabel">
                                            <div className="offcanvas-header">
                                                {/* <h5 id="offcanvasRightLabel"></h5> */}
                                                <button type="button" className="border-0 bg-transparent text-white">
                                                    <i className="fa-solid fa-xmark btn-close text-reset"
                                                        data-bs-dismiss="offcanvas" aria-label="Close"></i>
                                                </button>

                                            </div>
                                            <div className="offcanvas-body">
                                                <div className="Images animated fadeIn mt-2 container position-absolute">
                                                    <div className="row d-flex align-items-center text-start pb-3">
                                                        <div className="col-4">
                                                            <p className="mb-0 sort-by-text">Filter by</p>
                                                        </div>
                                                        <div className="col-lg-8"></div>
                                                    </div>
                                                    <div
                                                        className="row d-flex align-items-center justify-content-start filter_sort_wrapper">
                                                        <div className="col-lg-12 text-start">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="v-pills-home-tab" />
                                                            <label className="form-check-label mb-0 ps-2"
                                                                htmlFor="flexCheckDefault">
                                                                gender
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row d-flex align-items-center justify-content-start filter_sort_wrapper">
                                                        <div className="col-lg-12 text-start">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="v-pills-home-tab" />
                                                            <label className="form-check-label mb-0 ps-2"
                                                                htmlFor="flexCheckDefault">
                                                                year experience
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row d-flex align-items-center justify-content-start filter_sort_wrapper">
                                                        <div className="col-lg-12 text-start">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="v-pills-home-tab" />
                                                            <label className="form-check-label mb-0 ps-2"
                                                                htmlFor="flexCheckDefault">
                                                                languages spoken
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row d-flex align-items-center justify-content-start filter_sort_wrapper">
                                                        <div className="col-lg-12 text-start">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="v-pills-home-tab" />
                                                            <label className="form-check-label mb-0 ps-2"
                                                                htmlFor="flexCheckDefault">
                                                                Rating
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row d-flex align-items-center justify-content-start filter_sort_wrapper">
                                                        <div className="col-lg-12 text-start">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="v-pills-home-tab" />
                                                            <label className="form-check-label mb-0 ps-2"
                                                                htmlFor="flexCheckDefault">
                                                                Availability
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-2 explore__details__wrapper_one">
                    <div className="container-fluid">
                        <div className="row">
                            {specialistList.length !== 0 && specialistList !== null ? specialistList.map((elem: any) => (
                                <div className={`${gridType === 'col-lg-6' ? 'col-lg-6' : 'col-lg-3'} position-relative px-0 overflow-hidden specialist__profile--box`} key={elem.id}>
                                    <div className="explore_more_wrapper_one">
                                        <div className="explore-thumb">
                                            <div className="cs-image">
                                                <img src={elem.profile}
                                                    alt=""
                                                    className="img-fluid w-100 object-fit-cover" style={{ width: 'auto', height: '500px' }}
                                                    loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="explore-content">
                                            <h3 className="explore-heading mb-0">{elem.name.toUpperCase()}</h3>
                                            <p className="mb-0 specialst_degree pb-2">{elem.DoctorExperiences[0].areaofexpertise.toUpperCase()}</p>
                                            <span onClick={() => goToBuyProductPage(elem.uuid)} className="btn book-btn" >
                                                BOOK
                                            </span>
                                        </div>
                                    </div>
                                    <div className="hidden-content-when-hover-wrapper">
                                        <h3 className="explore-heading mb-0 pb-2">{elem.name.toUpperCase()}</h3>
                                        <p className="mb-0 specialst_degree">{elem.DoctorExperiences[0].areaofexpertise.toUpperCase()}</p>
                                        <p className="mb-0 specialist-gender">{elem.gender}</p>
                                        {/* <p className="mb-0 specialist-experties">BIOPSYCHOLOGY | COGNITIVE PSYCHOLOGY | <span
                                                className="d-block">COUNSELING
                                                PSYCHOLOGY</span></p> */}
                                        <p className="specialist-year-of-experience mb-0 py-0">{elem.DoctorExperiences[0].yearofexperience}</p>
                                        <p className="mb-0 specialist-langauge pb-3">{elem.language.toUpperCase()}</p>
                                        <span onClick={() => goToBuyProductPage(elem.uuid)} className="btn book-btn" >
                                            BOOK
                                        </span>
                                    </div>
                                </div>
                            )) :

                                <div className="col-lg-12">
                                    <div className="d-block text-center">
                                        <span className="text-white letter_spacing">No Data Available</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>

            </div>
        </>
    );

};


export default Specialist;