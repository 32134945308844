import React, { useContext } from "react";
import { Link, useNavigate, redirect } from "react-router-dom";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthContext from "../UseContext";
import Swal from "sweetalert2";

// or via CommonJS

const Cart: React.FC = () => {
  const [orgID, setOrgId] = useState<any>("");
  const [orderId, setOrderId] = useState<any>("");
  const [cartList, setCartList] = useState<any>([]);
  const [totalPrice, setTotalPrice] = useState<any>([]);
  const [cartPuckData, setCartPuckData] = useState<any>([]);
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }
  let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;
  let cartId = localStorage.getItem("cartId");
  let token = localStorage.getItem("token");
  const Swal = require("sweetalert2");

  useEffect(() => {
    getCartData();
  }, []);

  useEffect(() => {
    if (lastActiveCartId === null && !cartId) {
      window.location.href = "/app";
    }
  }, [lastActiveCartId, cartId]);

  const createOrder = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          successurl: "http://localhost:3000/success",
          cancelurl: "http://localhost:3000/cancel",
          cartId:
            lastActiveCartId !== undefined && cartId
              ? cartId
              : cartId
              ? cartId
              : lastActiveCartId,
          organizationId: 1,
          action: "command",
          command: [
            {
              agent: "createOrder",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setOrderId(result?.data?.data?.orderID);
          navigate(`/checkout/${result?.data?.data?.orderID}`);
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCartList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          cartId:
            lastActiveCartId !== undefined && cartId
              ? cartId
              : cartId
              ? cartId
              : lastActiveCartId,
          organizationId: 1,
          action: "command",
          command: [
            {
              agent: "cartList",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        setCartList(result?.data?.data);
        //Context API
        authContext.cart(cartList.length);

        const totalPriceOfItems = result?.data?.data;
        if (totalPriceOfItems) {
          const totalPrice = totalPriceOfItems.reduce(
            (accumulator: number, elem: any) => {
              return accumulator + Number(elem.total);
            },
            0
          );
          setTotalPrice(totalPrice);
        } else {
          setTotalPrice(0); // or whatever default value you want to set if the data is not available
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCartItem = async (uuid: any) => {
    Swal.fire({
      title: "Are you sure you want to delete this product?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              cartId:
                lastActiveCartId !== undefined && cartId
                  ? cartId
                  : cartId
                  ? cartId
                  : lastActiveCartId,
              productId: uuid,
              action: "command",
              command: [
                {
                  agent: "deleteCartItem",
                  appName: "selfMaximized",
                  folder: "order",
                },
              ],
            },
            {
              headers: { "x-access-token": token },
            }
          )
          .then((result) => {
            if (result?.data?.status === "success") {
              toast.success("Item deleted successfully!");
              getCartList();
            }
          })
          .catch((err) => {
            return toast.error("Something went wrong" + err);
          });
        Swal.fire({
          title: "Deleted!",
          text: "Your product has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const getCartListCb = useCallback(getCartList, [
    cartId,
    cartList.length,
    lastActiveCartId,
  ]);

  useEffect(() => {
    let token: any = localStorage.getItem("token");
    if (token) {
      getCartListCb();
    }
  }, [getCartListCb]);

  const getCartData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "CART",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setCartPuckData(data);
        } else {
          setCartPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const cartData: any = cartPuckData?.content?.find(
    (elem: any) => elem.type === "Cart"
  );
  return (
    <>
      <section className="custom-pt-1 custom-pb-2 parallaxie position-relative journal-detail-page-wrapper h-100">
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner">
                <h1 className="mb-0 banner-heading">
                  {cartData?.props?.title ? cartData?.props?.title : "CART"}
                </h1>
                <p className="mb-0 banner-description">
                  {cartData?.props?.description
                    ? cartData?.props?.description
                    : "THE LIST IS WHAT IS KNOWN AS A DYNAMIC SIZE ARRAY SINCE ITS SIZE GROWS AS MORE DATA IS ADDED TO IT, NEGATING THE NEED TO PRE-DEFINE A STATIC SIZE FOR THE LIST"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-content">
        {/* Product Quantity and total */}
        <section className="cart__page__wrapper py-0 cart__product__quantity__total position-relative d-none d-lg-block">
          <div className="container-fluid left-right-space">
            <div className="row pb-5 mb-3">
              <div className="col-lg-7">
                <h2 className="mb-0 products__text">PRODUCTS</h2>
              </div>
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-lg-6">
                    <h2 className="mb-0 products__text">TOTAL</h2>
                  </div>
                  <div className="col-lg-6"></div>
                </div>
              </div>
            </div>

            {cartList.length !== 0 && cartList !== null ? (
              cartList.map((elem: any) => (
                <div
                  className="row pb-5 mb-5 d-flex align-items-center"
                  key={elem.id}
                >
                  <div className="col-lg-7 pb-4 pb-lg-0">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-3 col-md-4 col-sm-4">
                        <div className="package__image__card">
                          <Link to={`/buyproduct/${elem.userUUID}/0`}>
                            <img
                              src={
                                elem.packageType === "Package"
                                  ? elem.image
                                  : elem.profile
                              }
                              alt=""
                              className="img-fluid w-100 package__image__card__img"
                              style={{ width: "auto", height: "auto" }}
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                      {elem.packageType !== "Package" ? (
                        <div className="col-lg-9 col-md-8 col-sm-8">
                          <div className="d-none d-lg-block">
                            <h3 className="mb-0 package__title">
                              DR.{elem.name.toUpperCase()}
                            </h3>
                            <p className="mb-0 package__description pb-3">
                              {elem.shorttitle.toUpperCase()} |{" "}
                              {elem.duration.toUpperCase()} HOUR VIRTUAL
                              APPOINTMENT
                            </p>
                          </div>
                          <div className="d-block d-lg-none pt-5">
                            <div className="d-flex justify-content-between">
                              <div>
                                <h3 className="mb-0 package__title">
                                  DR.{elem.name.toUpperCase()}
                                </h3>
                                <p className="mb-0 package__description pb-3">
                                  {elem.shorttitle.toUpperCase()} |{" "}
                                  {elem.duration.toUpperCase()} HOUR VIRTUAL
                                  APPOINTMENT
                                </p>
                              </div>
                              <div>
                                <p className="amount mb-0 text-center">
                                  <span className="dollar__symbol">$</span>
                                  <span className="amt ps-2">
                                    {elem.price}
                                    {`(Tax :${elem.tax.slice(0, -3)}%) `}{" "}
                                    <span className="dollar__symbol">-$ </span>
                                    {`${elem.total}`}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-9 col-md-8 col-sm-8">
                          <div className="d-none d-lg-block">
                            <h3 className="mb-0 package__title">
                              {elem.packageType.toUpperCase()} |{" "}
                              {elem.title.toUpperCase()}
                            </h3>
                            <p className="mb-0 package__description pb-3">
                              {elem.packageDuration.toUpperCase()} HOUR VIRTUAL
                              APPOINTMENT
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-7">
                        <p className="amount mb-0 text-center add__cart__amount">
                          <span className="dollar__symbol">$</span>
                          <span className="amt ps-2">
                            {elem.price}
                            {` (Tax :${elem.tax.slice(0, -3)}%) - `}{" "}
                            <span className="dollar__symbol">$ </span>
                            {`${elem.total}`}
                          </span>
                        </p>
                      </div>
                      <div className="col-lg-5 text-end">
                        <div className="total__card">
                          <button
                            className="procced__to__buy"
                            onClick={() => deleteCartItem(elem.uuid)}
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12 text-center">
                <span className="empty__cart__error__message text-white">
                  Cart is empty
                </span>
              </div>
            )}
          </div>
        </section>
        <section className="cart__page__wrapper py-0 cart__product__quantity__total position-relative d-block d-lg-none mt-5">
          <div className="container-fluid left-right-space">
            {cartList.length !== 0 && cartList !== null ? (
              cartList.map((elem: any) => (
                <div className="row pb-5 mb-5" key={elem.id}>
                  <div className="col-lg-7 pb-5 mb-5 pb-lg-0">
                    <div className="row d-flex align-items-center">
                      <div className="col-6">
                        <div className="package__image__card">
                          <Link to={"/cart/buyproduct"}>
                            <img
                              src={
                                elem.packageType === "Package"
                                  ? elem.image
                                  : elem.profile
                              }
                              alt=""
                              className="img-fluid w-100 package__image__card__img"
                              style={{ width: "auto", height: "auto" }}
                              loading="lazy"
                            />
                          </Link>
                          <p className="text-light">
                            {elem.packageType === "Package"
                              ? elem.image
                              : elem.profile}
                          </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="">
                          {elem.packageType === "Package" && (
                            <h3 className="mb-0 package__title">
                              {elem.packageType.toUpperCase()} |{" "}
                              {elem.title.toUpperCase()}
                            </h3>
                          )}
                          {elem.packageType === "Package" && (
                            <p className="mb-0 package__description pb-3">
                              {elem.packageDuration.toUpperCase()} HOUR VIRTUAL
                              APPOINTMENT
                            </p>
                          )}
                          {elem.packageType !== "Package" && (
                            <p className="mb-0 package__description pb-3">
                              11{elem.shorttitle.toUpperCase()} VIRTUAL
                              APPOINTMENT
                            </p>
                          )}
                          {elem.packageType !== "Package" && (
                            <p className="mb-0 package__description pb-3">
                              {elem.duration} HOUR VIRTUAL APPOINTMENT
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row">
                      <div className="col-8">
                        <p className="amount mb-0 text-center add__cart__amount mb-2">
                          <span className="dollar__symbol">$</span>
                          <span className="amt ps-2">
                            {elem.price}
                            {` (Tax :${elem.tax.slice(0, -3)}%) -`}{" "}
                            <span className="dollar__symbol">$ </span>
                            {`${elem.total}`}
                          </span>
                        </p>
                      </div>
                      <div className="col-4 text-end">
                        <div className="total__card">
                          <button
                            className="procced__to__buy"
                            onClick={() => deleteCartItem(elem.uuid)}
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <span className="empty__cart__error__message">Cart is empty</span>
            )}
          </div>
        </section>
        {cartList.length !== 0 && cartList !== null ? (
          <section className="continute__to__exploring position-relative py-4">
            <div className="container-fluid left-right-space">
              <div className="row d-flex align-items-center">
                <div className="col-lg-6 pb-4 pb-lg-0 order-2 order-md-1">
                  <h3 className="continue__to__exploring">
                    CONTINUE TO EXPLORING
                  </h3>
                </div>
                <div className="col-lg-6 text-start text-lg-end order-1 order-md-2">
                  <p className="mb-0 d-flex justify-content-start justify-content-lg-end align-items-center pb-5 pb-lg-0">
                    <span className="subtotal--text pe-2">SUBTOTAL</span>
                    <span className="subtotal--dollar pe-2">$</span>
                    <span className="subtotal--amount">{totalPrice}</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-start text-lg-end">
                  <button className="procced__to__buy" onClick={createOrder}>
                    CHECKOUT
                  </button>
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </div>
    </>
  );
};
export default Cart;
