import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

const UserRegistration: React.FC = () => {
  const [userDetails, setUserDetails] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
  });
  const [fnameValidation, setFnameValidation] = useState<any>("");
  const [lnameValidation, setLnameValidation] = useState<any>("");
  const [emailValidation, setEmailValidation] = useState<any>("");
  const [phoneValidation, setPhoneValidation] = useState<any>("");
  const [countryValidation, setCountryValidation] = useState<any>("");
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [countriesList, setCountriesList] = useState<any>([]);
  const [loader, setLoader] = useState<any>("");
  const [showPopup, setShowPopUp] = useState<any>("hide");
  const [userPuckData, setUserPuckData] = useState<any>([]);

  useEffect(() => {
    getCountryCodeList();
    getUserPuckData();
  }, []);

  const handleInputChange = (e: any) => {
    setShowPopUp("hide");
    e.preventDefault();
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: [value] });
    if (name === "fname" && value !== "") {
      setFnameValidation("");
    }
    if (name === "lname" && value !== "") {
      setLnameValidation("");
    }
    if (name === "email" && value !== "") {
      setEmailValidation("");
    }
    if (name === "phone" && value !== "") {
      setPhoneValidation("");
    }
    if (name === "country" && value !== "") {
      setCountryValidation("");
    }
  };

  const handleKeyDownEvent = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const handleNumericKeydownEvent = async (e: any) => {
    if (
      !(
        // Allow control keys
        (
          e.key === "Backspace" ||
          e.key === "Delete" ||
          // Allow numeric keys
          (e.key >= "0" && e.key <= "9") ||
          // Allow numeric keypad keys
          (e.key >= "Num0" && e.key <= "Num9") ||
          // Allow arrow keys
          e.key.includes("Arrow")
        )
      )
    ) {
      e.preventDefault();
    }
  };

  const getCountryCodeList = async () => {
    await axios
      .get("https://restcountries.com/v3.1/all")
      .then((result) => {
        setCountriesList(result.data);
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const registerUser = async () => {
    setShowPopUp("hide");
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactRegex = /^[0-9]{8,13}$/;

    let isPhoneValid = contactRegex.test(userDetails.phone);
    let isEmailValid = emailRegex.test(userDetails.email);

    if (
      !userDetails.fname ||
      !userDetails.lname ||
      !userDetails.phone ||
      !userDetails.email ||
      !selectedOption ||
      !isPhoneValid ||
      !isEmailValid
    ) {
      if (!userDetails.fname) {
        setFnameValidation("Please enter first name");
      } else {
        setFnameValidation("");
      }

      if (!userDetails.lname) {
        setLnameValidation("Please enter last name");
      } else {
        setLnameValidation("");
      }

      if (!userDetails.email) {
        setEmailValidation("Please enter email id");
      } else if (!isEmailValid) {
        setEmailValidation("Invalid email format");
      } else {
        setEmailValidation("");
      }

      if (!selectedOption) {
        setCountryValidation("Please select country");
      } else {
        setCountryValidation("");
      }

      if (!userDetails.phone) {
        setPhoneValidation("Please enter phone no");
      } else if (!isPhoneValid) {
        setPhoneValidation("Invalid contact number format.");
      } else {
        setPhoneValidation("");
      }
      return;
    }
    setLoader(true);

    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        email: `${userDetails.email}`,
        name: `${userDetails.fname} ${userDetails.lname}`,
        firstname: `${userDetails.fname}`,
        lastname: `${userDetails.lname}`,
        phone: `${userDetails.phone}`,
        country: `${selectedOption}`,
        gender: "",
        orgnizationId: 1,
        role: "user",
        baseLink: `${process.env.REACT_APP_LOCALHOST_URL_APP}/#`,
        action: "command",
        command: [
          {
            agent: "userRegister",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      })
      .then((result) => {
        if (result.data.status === "failed") {
          toast.info(result.data.message);
          setLoader(false);
        } else {
          toast.success("User registered successfully!");
          setUserDetails({ lname: "", fname: "", email: "", phone: "" });
          setSelectedOption(null);
          setShowPopUp("show");
          setTimeout(() => {
            setShowPopUp("hide");
          }, 1000);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (showPopup === "show") {
      const openModalButton = document.querySelector(
        "#openModal2"
      ) as HTMLButtonElement | null;
      if (openModalButton) {
        openModalButton.click();
      }
    }
  }, [showPopup === "show"]);

  const mappedOptions = countriesList.map((option: any) => ({
    value: option.cca2,
    label: `${option.cca2} - ${option.name.common}`,
  }));

  const handleCountry = (selectedOption: any) => {
    if (selectedOption !== "") {
      setCountryValidation("");
    }
    setSelectedOption(`${selectedOption.label}`);
  };

  const getUserPuckData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "USER FORM",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setUserPuckData(data);
        } else {
          setUserPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const userData = userPuckData?.content?.find(
    (elem: any) => elem.type === "UserForm"
  );

  console.log(selectedOption);
  return (
    <>
      <section className="pb-0 user-registration-flow-wrapper">
        <div className="container-fluid left-right-space ">
          <div className="row justify-content-center text-center py-4">
            <div className="col-12 col-lg-10 col-xl-8">
              <h1 className="user-registration-title mb-0">
                {userData?.props?.userRegistrationTitle ??
                  " USER REGISTRATION FLOW"}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 userregistration-wrapper-bg px-1 px-md-5">
              <div className="px-2 px-md-1 py-4 py-md-0">
                <h2 className="lets-fill-basic mb-0">
                  {userData?.props?.fillingDetails ??
                    "LET'S FILL BASIC DETAILS"}
                </h2>
                <p className="mb-0 before-embarking pb-2">
                  {userData?.props?.userRegistrationDescription ??
                    `BEFORE EMBARKING ON OUR JOURNEY TOGETHER, KINDLY ANSWER A FEW
                  ONBOARDING QUESTIONS IN JUST TWO QUICK STEPS.`}
                </p>
                <p className="mb-personal-details">
                  {userData?.props?.personalDetails ?? "PERSONAL DETAILS"}
                </p>
                <form className="pt-5" onSubmit={handleInputChange}>
                  <div className="row">
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="fname">
                        <span className="text-white pe-1 vertical-top">*</span>
                        {userData?.props?.userRegistrationFirstName ??
                          `FIRST NAME`}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="fname"
                        value={userDetails.fname}
                        autoComplete="off"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDownEvent}
                      />
                      <span className="text-danger letter-spacing mt-2 text-uppercase">
                        {fnameValidation}
                      </span>
                    </div>
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="lname">
                        <span className="text-white pe-1 vertical-top">*</span>
                        {userData?.props?.userRegistrationlastName ??
                          "LAST NAME"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lname"
                        value={userDetails.lname}
                        autoComplete="off"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDownEvent}
                      />
                      <span className="text-danger letter-spacing mt-2 text-uppercase">
                        {lnameValidation}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 pb-3">
                      <label htmlFor="email">
                        <span className="text-white pe-1 vertical-top">*</span>
                        {userData?.props?.userRegistrationEmail ?? "EMAIL"}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={userDetails.email}
                        autoComplete="off"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDownEvent}
                      />
                      <span className="text-danger letter-spacing mt-2 text-uppercase">
                        {emailValidation}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="tel">
                        <span className="text-white pe-1 vertical-top">*</span>
                        {userData?.props?.userRegistraionPhone ?? "PHONE"}
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        name="phone"
                        value={userDetails.phone}
                        autoComplete="off"
                        onChange={handleInputChange}
                        onKeyDown={handleNumericKeydownEvent}
                      />
                      <span className="text-danger letter-spacing mt-2 text-uppercase">
                        {phoneValidation}
                      </span>
                    </div>
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="country">
                        <span className="text-white pe-1 vertical-top">*</span>
                        {userData?.props?.userRegistraionCountry ?? "COUNTRY"}
                      </label>
                      {/* <input type="text" className="form-control" name="country" value="" autoComplete="off" onClick={handleCountryNames} onKeyDown={handleKeyDownEvent} /> */}
                      <div id="selfDropdown" className="language__wrapper">
                        <Select
                          value={selectedOption ? selectedOption.label : ""}
                          onChange={handleCountry}
                          options={mappedOptions}
                          isSearchable={true}
                          name="language"
                          className="text-dark text-uppercase"
                          styles={{
                            control: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              color: "inherit",
                              backgroundColor: "#fff",
                              letterSpacing: "1px",
                              border: state.isFocused
                                ? "2px solid black"
                                : "2px solid transparent", // Custom border style
                              boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                              "&:hover": {
                                borderColor: "black", // Border color on hover
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              color:
                                state.isFocused || state.isSelected
                                  ? "white"
                                  : "black", // Change color to black when focused or selected
                              backgroundColor: state.isSelected
                                ? "#000" // Custom background color for selected option
                                : state.isFocused
                                  ? "#000"  // Background color when focused
                                  : "#fff", // Default background color for other options
                              "&:active": {
                                backgroundColor: "transparent", // Remove default background color when option is clicked
                              },
                            }),
                            singleValue: (provided: any) => ({
                              ...provided,
                              color: "#000", // Set color of the selected option to white
                            }),
                            input: (provided: any) => ({
                              ...provided,
                              color: "#000",
                            }),
                            placeholder: (provided: any) => ({
                              ...provided,
                              color: "#000",
                            }),
                            menu: (provided: any) => ({
                              ...provided,
                              backgroundColor: "#000",
                            }),
                          }}
                          placeholder="Search..."
                        />
                      </div>
                      <span className="text-danger letter-spacing mt-2 text-uppercase">
                        {countryValidation}
                      </span>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-lg-12 text-end position-relative">
                      {loader ? (
                        <div className="ml-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : null}

                      {showPopup === "show" ? (
                        <button
                          className="next-btn"
                          type="button"
                          id="openModal2"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <span className="">
                            {userData?.props?.submit ?? "SUBMIT"}
                          </span>
                        </button>
                      ) : (
                        showPopup !== "show" &&
                        !loader && (
                          <button
                            className="next-btn"
                            type="button"
                            onClick={registerUser}
                          >
                            <span className="">
                              {" "}
                              {userData?.props?.submit ?? "SUBMIT"}
                            </span>
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="modal fade user__pop__wrapper p-0 "
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-fullscreen">
                <div className="modal-content user_response--popup py-5 position-relative">
                  <div className="modal-body text-center my-5 py-5">
                    <img
                      src="/images/user-registraion/login-logo.png"
                      alt="user-logo"
                      className="img-fluid"
                      style={{ width: "150px", height: "150px" }}
                      loading="lazy"
                    />
                    <h3 className="mb-0 thanks-for-your-response thanks--response">
                      THANKS FOR YOUR RESPONSE
                    </h3>
                    <p className="mb-0 you-will-receive">
                      "You Will Receive A Confirmation Via Email.
                    </p>
                  </div>
                  <div className="cancel__pop__icon" data-bs-dismiss="modal">
                    <i className="fa-solid fa-xmark text-white"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserRegistration;
