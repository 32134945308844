import React from "react";
import SpecialistProducts from "./SpecialistProducts";
import { useLocation } from "react-router-dom";

const BookSpecialistDetails: React.FC = () => {

    const location = useLocation();

    const specialistDetails = location?.state;
    // console.log(specialistDetails[0].userUUID);

    return (
        <>
        <div className="page-wrapper">
            <section className="py-0 custom-pt-1 packages__wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="blog_page_section mb-140 p-0">
                            <section className="search-bar-with-dropdown py-0 mb-3">
                                <div className="contaier-fluid left-right-space">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex align-items-center justify-content-end">
                                                <div className="header__search w-100">
                                                    <form className="form-search">
                                                        <div className="form-search__container">
                                                            <span className="form-search__icon-left">
                                                                <i className="fas fa-search"></i>
                                                            </span>
                                                            <input className="form-search__input" type="text"
                                                                placeholder="Appoinment type"/>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="filter_dropdown_package ps-1">
                                                    <button type="button" className="images-drop-down_package">
                                                        <i className="fas fa-filter"></i>
                                                    </button>
                                                    <div
                                                        className="images_packages animated-package fadeIn_package mt-2 container position-absolute">
                                                        <div className="row text-start">
                                                            <div className="col-lg-12 px-0">
                                                                <p className="mb-0 sort-by-text py-2">SINGULARA APPOINTMENT
                                                                    OPTIONS</p>
                                                                <p className="mb-0 sort-by-text pb-2">SINGULARA APPOINTMENT
                                                                    OPTIONS</p>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="py-0 packages__wrapper">
                                <div className="container-fluid px-0">
                                    <div className="row">
                                        <div className="blog_page_section mb-140 p-0">
                                            <div
                                                className="blog_messonry_button d-flex justify-content-center justify-content-md-start">
                                                <button className="active" data-filter=".view-packages">VIEW PACKAGES</button>

                                            </div>
                                            <div className="view-packages">
                                                <section className="py-0 specialist-detail-wrapper">
                                                    <div className="container-fluid">
                                                        <div
                                                            className="row no-gutters portfolio_page_gallery d-flex align-items-center pb-3 pb-lg-0">
                                                            <div
                                                                className="col-lg-6 col-md-12 col-sm-12 gird_item entertaiment life technology px-0 pb-3 pb-lg-0 position-relative">
                                                                <figure className="w-100 mb-0">
                                                                    <div>
                                                                        <img src={specialistDetails[0].profile}
                                                                            alt="specialist detail image" className="img-fluid"
                                                                            style={{width:'100%',height: '550px'}}
                                                                            loading="lazy"/>
                                                                        <div className="portfolio_text">
                                                                            <h3 className="mb-0">{specialistDetails[0].name}</h3>
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="col-lg-6 col-md-12 col-sm-12 gird_item business entertaiment others position-relative px-2 px-lg-0">
                                                                <div className="details-specialist-content ps-1 ps-md-5">
                                                                    <h2 className="mb-0 specialist-name">{specialistDetails[0].name}</h2>
                                                                    <p className="mb-0 specialst-degree">{specialistDetails[0].specialistExprience[0].areaofexpertise}</p>
                                                                    <p className="mb-0 specialist-gender pb-3">{specialistDetails[0].gender}</p>
                                                                    <p className="mb-0 specialist-experties">BIOPSYCHOLOGY |
                                                                        COGNITIVE PSYCHOLOGY | COUNSELING PSYCHOLOGY</p>
                                                                    <p className="specialist-year-of-experience mb-0 py-2">
                                                                        {specialistDetails[0].specialistExprience[0].yearofexperience} YEARS OF EXPERIENCE</p>
                                                                    <p className="mb-0 specialist-langauge pb-3">{specialistDetails[0].specialistExprience[0].language}</p>
                                                                </div>
                                                                <div className="specialist-detail-rating">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="pe-2 rating__count">4.6</span>
                                                                        <div>
                                                                            <i className="fa-solid fa-star"></i>
                                                                            <i className="fa-solid fa-star"></i>
                                                                            <i className="fa-solid fa-star"></i>
                                                                            <i className="fa-solid fa-star"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                                {specialistDetails.length > 0 && (
                                                    <SpecialistProducts userUUID={specialistDetails[0].userUUID}/>
                                                )}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
          
        </>
    )
};

export default BookSpecialistDetails;