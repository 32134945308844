import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import bookSpecialistImage from "../../../web/src/images/specialist.jpg";
import bookPackageImage from "../../../web/src/images/packages.png";
import AuthContext from "../UseContext";
import bannerImg from "../images/new-img.png";
import { toast } from "react-toastify";
import LandingPopUpLogo from "../../src/images/logos/WHITELOGONOBG.png";
import ShimmerUI from "./Shimmer";

const Book: React.FC = () => {
  const [journalData, setJournalData] = useState<any>([]);
  const authContext = useContext(AuthContext);
  const [fixedText, setFixedText] = useState<any>("SELF MAXIMIZED"); // Initial fixed text
  const [isScrolled, setIsScrolled] = useState<any>(false);
  const [scrollPositionNo, setScrollPosition] = useState<any>(0);
  const [popUpPuckData, setPopUpPuckData] = useState<any>([]);
  const [puckData, setPuckData] = useState<any>([]);
  const [puckloader, setPuckLoader] = useState<any>(false);

  useEffect(() => {
    getPuckData();
    getJournals();
    getSpecialistWiseBlogs();
    getPopUpPuckData();
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const getPopUpPuckData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "POP UP",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setPopUpPuckData(data);
        } else {
          setPopUpPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const popUp = popUpPuckData?.content?.find(
    (elem: any) => elem.type === "PopUp"
  );

  const getSpecialistWiseBlogs = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        doctorUuid: "310b6ea1-d475-4536-b61a-82882a403e78",
        blog_id: 18,
        action: "command",
        command: [
          {
            agent: "getSpecialistBlog",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      })
      .then((result) => {
        console.log(result);
      });
  };
  const getPuckData = async () => {
    setPuckLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "LANDING PAGE",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const data = JSON.parse(res?.data?.data?.response[0]?.data);
          setPuckData(data);
          setPuckLoader(false);
        } else {
          setPuckData(null);
          setPuckLoader(false);
        }
      })
      .catch((err) => {
        setPuckLoader(false);
        return err;
      });
  };

  const getJournals = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 4,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "blogList",
            appName: "selfMaximized",
            folder: "blogs",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setJournalData(result?.data?.data);
        } else {
          setJournalData([]);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const landingPage: any = puckData?.content?.find(
    (item: any) => item.type === "LandingPage"
  );

  console.log(landingPage);

  const bookSpecialist = {
    backgroundImage: `url(${
      landingPage?.props?.bookSpecImage || bookSpecialistImage
    })`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e4e4e4",
  };

  const bookImages = {
    backgroundImage: `url(${
      landingPage?.props?.bookPackImg || bookPackageImage
    })`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }
  let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;

  const getCartList = async () => {
    let token = localStorage.getItem("token");

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          cartId: lastActiveCartId,
          organizationId: 1,
          action: "command",
          command: [
            {
              agent: "cartList",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          authContext.cart(result?.data?.data.length);
        } else {
          return result?.data?.message;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let token: any = localStorage.getItem("token");
    if (lastActiveCartId !== undefined && token) {
      getCartList();
    }
  }, [lastActiveCartId]);

  useEffect(() => {
    const handleScroll = () => {
      const mobileView = window.innerWidth <= 768;
      const scrollPosition = window.scrollY;
      setScrollPosition(scrollPosition);

      if (mobileView) {
        if (scrollPosition < 400) {
          setIsScrolled(false);
          setFixedText(
            landingPage?.props?.bannerCenteredText || "SELF MAXIMIZED"
          );
        } else if (scrollPosition > 1582 && scrollPosition < 1982) {
          setIsScrolled(false);
          setFixedText(landingPage?.props?.about || "ABOUT");
        } else if (scrollPosition > 1982 && scrollPosition < 3980) {
          setIsScrolled(false);
          setFixedText(landingPage?.props?.journal || "JOURNAL");
        } else if (scrollPosition > 3980 && scrollPosition < 4500) {
          setIsScrolled(false);
          setFixedText("INSTAGRAM");
        } else {
          setIsScrolled(false);
          setFixedText("");
        }
      } else {
        if (scrollPosition < 400) {
          setIsScrolled(false);
          setFixedText(landingPage?.props?.landingPage || "SELF MAXIMIZED");
        } else if (scrollPosition > 800 && scrollPosition < 1400) {
          setIsScrolled(false);
          setFixedText(landingPage?.props?.about || "ABOUT");
        } else if (scrollPosition > 1400 && scrollPosition < 2300) {
          setIsScrolled(false);
          setFixedText(landingPage?.props?.journal || "JOURNAL");
        } else if (scrollPosition > 2300 && scrollPosition < 3000) {
          setIsScrolled(false);
          setFixedText("INSTAGRAM");
        } else {
          setIsScrolled(false);
          setFixedText("");
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPositionNo]); // Re-run effect when landingPage or scrollPosition changes

  useEffect(() => {
    const handlePopUp = () => {
      let modal: any;

      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        modal = document.getElementById("popUpModal");
        if (modal) {
          modal.click();
        }
      }
    };

    window.addEventListener("scroll", handlePopUp);

    return () => {
      window.removeEventListener("scroll", handlePopUp);
    };
  }, []);

  const bookSpecialistText =
    landingPage && landingPage?.props?.bookSpecialistText;
  const bookPackageText = landingPage && landingPage?.props?.bookPackageText;

  return puckloader ? (
    <ShimmerUI />
  ) : (
    <>
      <div
        className={`custom-pt-1 custom-pb-2 custom-pt-1-1 parallaxie position-relative  global-wellness__reimagined new-custom-pt-1 ${
          isScrolled ? "scrolled" : ""
        }`}
        style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${
            landingPage?.props?.bannerImg
              ? landingPage?.props?.bannerImg
              : bannerImg
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: scrollPositionNo === 0 ? 0 : "5",
        }}
        data-overlay="6"
        id="home__banner1"
      >
        <div className="container-fluid-1 left-right-space d-flex justify-content-center align-items-center h-100 mt-lg-5 pt-lg-5">
          <div className="row justify-content-center text-center align-items-center">
            <div className="col-12 col-lg-12 col-xl-12 ">
              <div
                className="logo pt-5 d-flex justify-content-center align-items-center"
                id="logoWrapper"
              >
                {window.innerWidth <= 768 &&
                scrollPositionNo > 400 &&
                scrollPositionNo < 1582 ? (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="position-fixed"
                        style={{ margin: "auto", zIndex: "999999999999999" }}
                      >
                        {window.innerWidth <= 768 &&
                        scrollPositionNo >= 472 &&
                        scrollPositionNo <= 913 ? (
                          <Link
                            to={"/categories"}
                            className="split__screen--link mb-0 font-bold"
                            style={{
                              fontSize: "72px",
                              lineHeight: "86.2px",
                              color: "white",
                              letterSpacing: "5px",
                            }}
                          >
                            {bookSpecialistText.split(" ")[0]}
                            <br />
                            {bookSpecialistText.split(" ").slice(1).join(" ")}
                          </Link>
                        ) : null}
                      </div>
                      <div
                        className="position-fixed"
                        style={{ margin: "auto", zIndex: "999999999999999" }}
                      >
                        {window.innerWidth <= 768 &&
                        scrollPositionNo >= 913 &&
                        scrollPositionNo <= 1582 ? (
                          <Link
                            to={"/packages"}
                            className="split__screen--link"
                            style={{
                              fontSize: "72px",
                              lineHeight: "86.2px",
                              color: "white",
                              letterSpacing: "5px",
                            }}
                          >
                            {bookPackageText.split(" ")[0]} <br />
                            {bookPackageText.split(" ").slice(1).join(" ")}
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <h1
                    className="mb-0 font-bold "
                    style={{
                      position: "fixed",
                      zIndex: "999999999999999",
                      pointerEvents: "none",
                      color: landingPage
                        ? landingPage?.props.bannerTextColor
                        : "white",
                    }}
                  >
                    {fixedText}{" "}
                  </h1>
                )}
                {window.innerWidth >= 768 &&
                scrollPositionNo > 400 &&
                scrollPositionNo < 800 ? (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="position-fixed"
                        style={{
                          left: 0,
                          margin: "auto",
                          minWidth: "50%",
                          zIndex: "999999999999999",
                        }}
                      >
                        <Link
                          to={"/categories"}
                          className="split__screen--link mb-0 font-bold"
                          style={{
                            fontSize: "95px",
                            lineHeight: "100px",
                            color: landingPage
                              ? landingPage?.props.bannerTextColor
                              : "white",
                            letterSpacing: "5px",
                          }}
                        >
                          <span className="d-block">
                            {bookSpecialistText.split(" ")[0]}
                          </span>{" "}
                          <span>
                            {bookSpecialistText.split(" ").slice(1).join(" ")}
                          </span>
                        </Link>
                      </div>
                      <div
                        className="position-fixed"
                        style={{
                          right: 0,
                          minWidth: "50%",
                          margin: "auto",
                          zIndex: "999999999999999",
                        }}
                      >
                        <Link
                          to={"/packages"}
                          className="split__screen--link"
                          style={{
                            fontSize: "95px",
                            lineHeight: "100px",
                            letterSpacing: "5px",
                            color: landingPage
                              ? landingPage?.props.bannerTextColor
                              : "white",
                          }}
                        >
                          <span className="d-block">
                            {bookPackageText.split(" ")[0]}
                          </span>{" "}
                          <span>
                            {bookPackageText.split(" ").slice(1).join(" ")}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <h1
                    className="mb-0 font-bold "
                    style={{
                      position: "fixed",
                      zIndex: "999999999999999",
                      pointerEvents: "none",
                      color: landingPage
                        ? landingPage?.props.bannerTextColor
                        : "white",
                    }}
                  >
                    {fixedText}{" "}
                  </h1>
                )}
              </div>

              <div className="get__started--btn pt-5 mt-5">
                <Link to={"categories"} className="btn book-btn mt-5">
                  GET STARTED
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        {/* Split Screen */}
        <section className="py-0">
          <div className="container-fluid">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 px-0 col-md-6 col-sm-12 position-relative">
                <div className="split-pane" style={bookSpecialist}>
                  <div className="split__text">
                    <Link
                      to={"/categories"}
                      className="split__screen--link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div
                className="split-pane col-lg-6 col-md-6 col-sm-12 position-relative"
                style={bookImages}
              >
                <div className="split__text">
                  <Link to={"/packages"} className="split__screen--link"></Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-wrapper">
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="about-description mb-0 text-uppercase mt-5 mt-lg-0">
                  {landingPage
                    ? landingPage?.props.text
                    : "An About Us page is a section on a website that provides information about a company, organization, or individual. It's an opportunity to tell your brand's story, share your vision, introduce team members, and outline your history, values, and achievements."}
                </h3>
              </div>
              <h6
                className="text-light mt-4 cursor_pointer"
                style={{ letterSpacing: "1px" }}
              >
                <Link to={"/about"} className="learn_mor text-light">
                  LEARN MORE
                </Link>
              </h6>
            </div>
          </div>
        </section>

        <section className="journal-wrapper pt-0 pb-4">
          {/* <div className="container-fluid left-right-space">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="journal-headig mb-0 fw-bold pb-5">JOURNAL</h2>
                            </div>
                        </div>
                    </div> */}
          <div className="container-fluid">
            <div className="row">
              {journalData.map((elem: any) => (
                <div className="col-lg-6 position-relative px-0" key={elem.id}>
                  <Link
                    to={`/journaldetails/${elem.id}`}
                    className="journal-box"
                    state={elem}
                    onClick={handleClick}
                  >
                    <div className="journal-thumb">
                      <div className="cs-image">
                        <img
                          src={elem.image}
                          alt="journal"
                          className="img-fluid w-100 main-img"
                          style={{ width: "auto", height: "500px" }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="journal-content">
                      <p className="journal-date-title mb-0 text-uppercase">
                        {moment(elem.onCreate).format("MMMM D, YYYY")}
                      </p>
                      <p className="mb-0 title-of-the-journal py-2">
                        <span className="d-block">{elem.title}</span>
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="revolution-in-holistic py-0">
          <div className="container-fluid left-right-space">
            <div className="row pb-3 pb-lg-5">
              <div className="col-lg-12 text-end">
                <Link to={"journal"} className="view-all" onClick={handleClick}>
                  VIEW ALL
                </Link>
              </div>
            </div>
            {/* <div className="row">
                        <div className="col-lg-10 mx-auto">

                            <p className="mb-0 become-speciality pb-3">
                                BECOME A SPECIALIST
                            </p>
                            <h2 className="rvolution-title">
                                MEDCITY HAS BEEN PRESENT IN EUROPE SINCE 1990, OFFERING INNOVATIVE SOLUTIONS,
                                SPECIALIZING IN MEDICAL SERVICES FOR TREATMENT OF MEDICAL INFRASTRUCTURE.
                            </h2>

                        </div>
                    </div> */}
          </div>
        </section>

        {/* <section className="become-speciality-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 position-relative px-0">
                            <NavLink to="#" className="become_specialist">
                                <div className="thumb">
                                    <div className="cs-image">
                                        <img src="/images/about/10.jpg" alt="journal"
                                            className="img-fluid w-100 main-img" style={{width:'auto',height:'500px'}}
                                            loading="lazy"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <p className="journal-date-title mb-0 mb-2">THOMAS - PSYCHOLOGIST</p>
                                    <p className="mb-0 specialist-description">Medcity has been present in Europe since
                                        1990, offering innovative solutions,</p>

                                </div>
                            </NavLink>
                        </div>
                        <div className="col-lg-4 position-relative px-0">
                            <NavLink to="#" className="become_specialist">
                                <div className="thumb">
                                    <div className="cs-image">
                                        <img src="/images/about/12.jpg" alt="journal"
                                            className="img-fluid w-100 main-img" style={{width:'auto',height:'500px'}}
                                            loading="lazy"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <p className="journal-date-title mb-0 mb-2">THOMAS - PSYCHOLOGIST</p>
                                    <p className="mb-0 specialist-description">Medcity has been present in Europe since
                                        1990, offering innovative solutions,</p>


                                </div>
                            </NavLink>
                        </div>
                        <div className="col-lg-4 position-relative px-0">
                            <NavLink to="#" className="become_specialist">
                                <div className="thumb">
                                    <div className="cs-image">
                                        <img src="/images/about/10.jpg" alt="journal"
                                            className="img-fluid w-100 main-img" style={{width:'auto',height:'500px'}}
                                            loading="lazy"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <p className="journal-date-title mb-0 mb-2">THOMAS - PSYCHOLOGIST</p>
                                    <p className="mb-0 specialist-description">Medcity has been present in Europe since
                                        1990, offering innovative solutions,</p>

                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section> */}

        {/* <section className="pt-0 register-now pb-5">
                    <div className="container-fluid left-right-space">
                        <div className="row">
                            <div className="col-lg-12">
                                <Link to={'specialistdetails'} className="register-btn" onClick={handleClick}>REGISTER NOW</Link>
                            </div>
                        </div>
                    </div>
                </section> */}

        <section className="instagram-wrapper py-0">
          <div className="container-fluid left-right-space">
            {/* <div className="row">
                            <div className="col-lg-12">
                                <h2 className="about-headig mb-0 fw-bold pb-5">INSTAGRAM</h2>
                            </div>
                        </div> */}
          </div>
          {/* <div className="container-fluid"> */}
          {/* </div> */}
        </section>
        <input
          className="next-btn"
          type="hidden"
          id="popUpModal"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        />
        <section
          className="custom-pt-1 pb-0 user-registration-flow-wrapper"
          id="bigPopUp"
        >
          <div className="container-fluid left-right-space extra-space-container">
            <div className="row mt-5" id="modalPopUp">
              <div
                className="modal fade user__pop__wrapper p-0 "
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-fullscreen transform-none transition-none">
                  <div className="modal-content py-5 position-relative fullscreen-pop-up">
                    <div className="modal-body my-5 py-5 ps-5">
                      <img
                        src={
                          popUp?.props?.landingPopUpLogo
                            ? popUp?.props?.landingPopUpLogo
                            : LandingPopUpLogo
                        }
                        alt="user-logo"
                        className="img-fluid"
                        style={{ width: "180px", height: "180px" }}
                        loading="lazy"
                      />
                      <div className="">
                        <h3 className="mb-0 thanks-for-your-response thanks--response">
                          {popUp?.props?.greetingResponse
                            ? popUp?.props?.greetingResponse
                            : `THANKS FOR YOUR RESPONSE`}
                        </h3>
                        <p className="mb-0 you-will-receive">
                          {popUp?.props?.confirmationMessage
                            ? popUp?.props.confirmationMessage
                            : "You Will Receive A Confirmation Via Email."}
                        </p>
                      </div>
                    </div>
                    <div className="cancel__pop__icon" data-bs-dismiss="modal">
                      <i className="fa-solid fa-xmark text-white bg-dark"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* THIS IS INSTAGRAM CODE - THIS IS ONE LINE CODE , IF YOU PAY FOR IT THEN THE FREE PRIVIEW MESSAGE WILL DISAPPEAR */}
        <div
          className="elfsight-app-8e14812b-0edc-4fc0-a319-6a123d5b7812"
          style={{ zIndex: 0 }}
        ></div>
      </div>
    </>
  );
};

export default Book;
