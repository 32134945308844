import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContextProvider } from "./UseContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <div className="page-wrapper">
    <AuthContextProvider>
      <App />
      <ToastContainer style={{ zIndex: "99999999999999" }} />
    </AuthContextProvider>
  </div>
);
