import React, { useState } from "react";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface BuyNowCartProps {
  buyNowCartId : number
}
const CheckoutForm: React.FC<BuyNowCartProps> = ({buyNowCartId}) => {

    console.log(buyNowCartId);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
  
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
  
      if (!stripe || !elements) {
          return;
      }
  
      setIsLoading(true);
  
      try {
        let result : any = ''
          if(buyNowCartId){
            result = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: `${process.env.REACT_APP_LOCALHOST_URL}/success/${buyNowCartId}`,
              },
            });
          }else{
            result = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: `${process.env.REACT_APP_LOCALHOST_URL}/success/${0}`,
              },
            });
          }
        
          if(result){
            setIsLoading(false)
            return toast.error(result.error.message);
          }

      }catch (error) {
        setMessage('Error confirming payment. Please try again.'+ error);
      }
  
      setIsLoading(false);
  };
  
  
    const paymentElementOptions : any = {
      layout: "tabs"
    }
    return (
        <>
        <form onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions}/>
            <button disabled={isLoading || !stripe || !elements} id="submit" className="proceed__to__buy_now mt-4 pay__now--payment" >
              <span id="button-text" >
                {isLoading ? <span className="text-white">PAYMENT IS UNDER PROCESS...</span> : "PAY NOW"}
              </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
        </>
    )
};


export default CheckoutForm;