import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  PinterestIcon,
  FacebookIcon,
} from "react-share";

const JournalDetails: React.FC = () => {
  const location = useLocation();

  const currentUrl = `${window.location.origin}${location.pathname}`;
  const [journalDetails, setJournalDetails] = useState<any>([]);
  const [blogs, setMoreBlogs] = useState<any>([]);
  const [categoryWiseBlogs, setCategoryWiseBlogs] = useState<any>([]);
  const [journalData, setNewJournalData] = useState<any>([]);
  const { id } = useParams();

  console.log(journalDetails);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    getSpecialistBlogs();
  }, [id, journalDetails.author_uuid]);

  useEffect(() => {
    getJournalsByCategoryName();
  }, [
    blogs.length === 0,
    journalDetails.category && journalDetails.category[0].name,
  ]);

  useEffect(() => {
    getAllBlogs();
  }, []);

  useEffect(() => {
    getSingleBlogData();
  }, [id]);

  const getSingleBlogData = async () => {
    axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 1,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
              {
                field: "id",
                operator: "eq",
                value: id,
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "blogList",
            appName: "selfMaximized",
            folder: "blogs",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setJournalDetails(result?.data?.data[0]);
        } else {
          setJournalDetails([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  console.log(journalDetails);
  const getJournalsByCategoryName = async () => {
    if (journalDetails) {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          category: journalDetails.category
            ? journalDetails.category[0].name
            : "",
          blogId: id ? id : "",
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "blogListByCategory",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        })
        .then((result) => {
          const newData = result?.data?.data[0].blog_data;
          setCategoryWiseBlogs(newData);
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    }
  };
  const getSpecialistBlogs = async () => {
    if (journalDetails) {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          doctorUuid: journalDetails.author_uuid,
          blog_id: id,
          action: "command",
          command: [
            {
              agent: "getSpecialistBlog",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            setMoreBlogs(result?.data?.data?.response);
          } else {
            setMoreBlogs([]);
          }
        })
        .catch((err) => {
          return toast.error("Something went wrong", err);
        });
    }
  };

  const getAllBlogs = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 4,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "blogList",
            appName: "selfMaximized",
            folder: "blogs",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setNewJournalData(result?.data?.data);
        } else {
          setNewJournalData([]);
        }
      });
  };

  const checkBlogStatus = () => {
    const checkBlog =
      blogs.length === 0 && categoryWiseBlogs.length !== 0
        ? categoryWiseBlogs
        : blogs.length === 0 && categoryWiseBlogs.length === 0
        ? journalData
        : blogs;

    return checkBlog.length !== 0 && checkBlog !== null ? (
      checkBlog.map((elem: any) => (
        <Link
          to={`/journaldetails/${elem.id}`}
          state={elem}
          className="col-lg-6 position-relative px-2 px-md-0"
          key={elem.id}
        >
          <a href="#" className="journal-box">
            <div className="journal-thumb">
              <div className="cs-image">
                <img
                  src={elem.image}
                  alt="journal"
                  className="img-fluid w-100 main-img"
                  style={{ width: "auto", height: "520px" }}
                />
              </div>
            </div>
            <div className="journal-content">
              <p className="mb-0 title-of-the-journal py-1">
                <span className="d-block">{elem.title.toUpperCase()}</span>
              </p>
              <Link
                to={`/buyproduct/${elem?.author ? elem?.author_uuid : 0}/0`}
              >
                <p className="journal-date-title mb-0 text-uppercase text-decoration-underline">
                  BY. {elem?.author ? elem?.author : "ADMIN"}
                </p>
              </Link>
            </div>
          </a>
        </Link>
      ))
    ) : (
      <span className="text-white letter_spacing text-center">
        NO MORE BLOGS AVAILABLE
      </span>
    );
  };

  return (
    <>
      <section
        className="custom-pt-1 custom-pb-2 parallaxie position-relative journal-detail-page-wrapper h-100 journal-details_banner"
        style={{
          backgroundImage: `url(${journalDetails.image})`,
          // backgroundPosition: " center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        data-overlay="6"
      >
        <div className="container-fluid position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner pt-5 pt-lg-0">
                <p className="mb-0 journal-detail-date-name text-uppercase">
                  {moment(journalDetails.onCreate).format("MMMM D, YYYY")}
                </p>
                <h1 className="international-day mb-0">
                  {journalDetails.title}
                </h1>
                <Link
                  to={
                    journalDetails?.author_uuid === ""
                      ? "/about"
                      : `/buyproduct/${
                          journalDetails?.author
                            ? journalDetails?.author_uuid
                            : 0
                        }/0`
                  }
                >
                  <h3 className="text-white mb-0 text-uppercase cursor_pointer journal-author text-decoration-underline">
                    BY. {journalDetails.author}
                  </h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-content">
        <section className="the-journy-wrapper">
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12 pb-2">
                <p className="mb-0 the-journy py-2">THE JOURNEY</p>
                <p className="mb-0 journy-description pb-5 text-uppercase">
                  {journalDetails.description}
                </p>
              </div>
              <div className="col-lg-12 pb-2">
                <div className="">
                  <div className="social-media-wrapper d-flex pt-3 position-relative">
                    <TwitterShareButton
                      url={currentUrl}
                      title={journalDetails.title}
                    >
                      <div className="social-icons text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="none"
                          className="mb-1"
                          viewBox="0 0 512 512"
                          id="twitter"
                        >
                          <g clip-path="url(#a)">
                            <rect
                              width="512"
                              height="512"
                              fill="#000"
                              rx="60"
                            ></rect>
                            <path
                              fill="#fff"
                              d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="a">
                              <rect width="512" height="512" fill="#fff"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </TwitterShareButton>

                    <div className="social-icons one">
                      <FacebookShareButton
                        url={currentUrl}
                        hashtag="#YourHashtag"
                      >
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="24px"
                            height="24px"
                          >
                            <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.462.099 2.794.142v3.24l-1.917.001c-1.503 0-1.794.714-1.794 1.761v2.31h3.588l-.467 3.622h-3.121V24h6.116C23.407 24 24 23.407 24 22.676V1.325C24 .593 23.407 0 22.675 0z" />
                          </svg>
                        </i>
                      </FacebookShareButton>
                    </div>

                    <div className="social-icons two">
                      <PinterestShareButton
                        url={currentUrl}
                        media={journalDetails.image}
                        description={journalDetails.description}
                      >
                        <i
                          className="fab fa-pinterest mt-1"
                          style={{ fontSize: "26px" }}
                        ></i>
                      </PinterestShareButton>
                    </div>

                    <div className="social-icons">
                      <WhatsappShareButton
                        url={currentUrl}
                        title={journalDetails.title}
                      >
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="24px"
                            height="24px"
                          >
                            <path d="M17.472 14.382c-.297-.149-1.757-.867-2.03-.967-.273-.099-.471-.148-.67.149-.198.297-.768.966-.94 1.164-.173.198-.347.223-.644.075-.297-.149-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.654-2.059-.173-.297-.018-.458.13-.606.134-.134.298-.347.446-.521.149-.174.198-.298.298-.496.099-.198.05-.372-.025-.521-.075-.149-.669-1.611-.916-2.206-.242-.579-.487-.499-.67-.499-.173-.012-.372-.012-.571-.012a1.094 1.094 0 00-.795.372c-.273.297-1.048 1.024-1.048 2.5 0 1.476 1.073 2.906 1.224 3.104.149.198 2.117 3.234 5.13 4.536.717.311 1.276.497 1.712.635.72.229 1.376.197 1.89.12.576-.086 1.757-.719 2.006-1.413.248-.695.248-1.291.173-1.413-.074-.123-.272-.198-.57-.347m-5.47 7.533h-.002a9.87 9.87 0 01-5.031-1.378l-.362-.214-3.74.982.998-3.648-.235-.375a9.861 9.861 0 01-1.51-5.287c.001-5.454 4.436-9.885 9.892-9.885a9.823 9.823 0 017.011 2.903 9.777 9.777 0 012.904 6.993c-.003 5.454-4.435 9.885-9.891 9.885m8.413-18.3A11.815 11.815 0 0012.002 0C5.374 0 .002 5.372 0 11.998c0 2.117.553 4.192 1.604 6.018L0 24l6.125-1.605a11.982 11.982 0 005.874 1.499h.005c6.624 0 11.996-5.372 11.998-11.998a11.93 11.93 0 00-3.513-8.486" />
                          </svg>
                        </i>
                      </WhatsappShareButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="more-for-you py-0">
        <div className="container-fluid left-right-space">
          <div className="row pb-5">
            <div className="col-lg-12">
              <h2 className="mb-0 more-for-you-heading">MORE FOR YOU</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid overflow-hidden">
          <div className="row no-gutters portfolio_page_gallery">
            {checkBlogStatus()}
          </div>
        </div>
      </section>
    </>
  );
};

export default JournalDetails;
